import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import AppLayout from "../../components/applayout"
import SEO from "../../components/seo"
import WebsiteNav from "../../components/websitenav"
import { LeftNavSection } from "../../components/leftnav"
import Segment, {SegmentSubTitle, SegmentTitle} from "../../components/segment"
import HubspotButton from "../../components/hubspotbutton"
import ErrorMessage from "../../components/errormessage"
import TextDiv from "../../components/textdiv"
import Button, { ButtonWrapper } from "../../components/button"
import ErrorState from "../../components/errorstate"

import { fetchWebsites } from "../../appstate/websites"
import { useAuth } from "../../services/auth"
import { handleEventError } from "../../services/error"
import { useTheme } from "../../services/theme"
import { callThunk } from "../../services/helper"

const AddFormProvider = ({websiteId}) => {

    //const [formProvider, setFormProvider] = useState(CONSTANTS.FORM_PROVIDER_HUBSPOT)
    const [validationError, setValidationError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loadError, setLoadError] = useState(false)

    const currentWebsite = useSelector(state => state.websites.find(website => website.submissionId === websiteId))
    const dispatch = useDispatch()
    const auth = useAuth()
    const user = auth.getUser() 
    const theme = useTheme()

    useEffect(() => {
        if(!user || currentWebsite){
            return 
        }

        //refresh websites if state is stale
        let unmounted = false
        const refreshWebsites = async () => {
            try {
                await callThunk(dispatch, fetchWebsites, [user])
            } catch (err) {
                handleEventError(err, user, false)
                if(!unmounted) {
                    setLoadError(true)
                }
            }
        } 
        refreshWebsites()
        return () => unmounted = true

    },[user, currentWebsite, dispatch])
    
    if(!websiteId) {
        navigate("/app/websites")
        return null
    }
    const domain = currentWebsite ? currentWebsite.submissionData.domain.slice(8) : ''
    const stage = currentWebsite ? currentWebsite.submissionData.stage : ''


    return (
        <AppLayout
            contextTitle={domain}
            contextCategory={stage}
            ctaPath={`/help/integrations/hubspot-connect`}
            ctaContent="See FAQs"
        >
            <SEO title="Add Form" />
            {
                !loadError &&
                <>
                    <WebsiteNav websiteId={websiteId}/>
                    <LeftNavSection first="true">
                        <Segment header={<SegmentTitle>Let's add a form</SegmentTitle>}>
                            <SegmentSubTitle marginBottom="15px">Connect to a different Hubspot account.</SegmentSubTitle>
                            <TextDiv 
                                maxWidth="540px"
                                marginBottom="20px"
                                align="left"
                                breakpoint={theme.breakpoints.mobile}
                                responsiveAlign="center"
                            >
                                Make sure you sign out on Hubspot before you start connecting to a different Hubspot account.   
                            </TextDiv>
                            <HubspotButton
                                show={true}
                                redirectPath={`/app/websites/${websiteId}/forms/create`}
                                setValidationError={setValidationError}
                                setErrorMessage={setErrorMessage}
                            />
                            <ErrorMessage paddingBottom="15px">
                                {validationError? errorMessage : ""}
                            </ErrorMessage>
                            <ButtonWrapper align="left" maxWidth="540px">
                                <Link to={`/app/websites/${websiteId}/forms/create`}>
                                    <Button type="button" secondary="true">Back</Button>
                                </Link>
                            </ButtonWrapper>
                        </Segment>
                    </LeftNavSection>
                </>
            }
            <ErrorState
                show={loadError}
                paddingTop="10%" 
                breakpoint={theme.breakpoints.tablet} 
                responsiveStyle="padding-left: 10%; padding-right: 10%;"
                title="Oops! Couldn't load your website."
            >
                Something's not right. Try refreshing the page or reach out to customer support.
            </ErrorState>
        </AppLayout>
    )
}
export default AddFormProvider