import React from "react"
import styled from "styled-components"

export const SegmentText = styled.div`

    ${({marginTop}) => marginTop ? `margin-top: ${marginTop};` : ""}
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
    }

`

export const SegmentCategory = styled.div`
    margin-top: 0px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: left;
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    ${({paddingRight}) => paddingRight ? `padding-right: ${paddingRight};` : ""}
    ${({color}) => color ? `color: ${color};` : ""}
    
    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        text-align: center;
        font-size: 14px;
        position: static;
        padding-right: 0px;    
    }

`

export const SegmentTitle = styled.h2`
    margin-top: 0px;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    position: sticky;
    top: 115px;
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    ${({paddingRight}) => paddingRight ? `padding-right: ${paddingRight};` : ""}

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        text-align: center;
        font-size: 20px;
        position: static;
        padding-right: 0px;    
    }

`

export const SegmentSummary = styled.h4`
    margin-top: 0px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    position: sticky;
    top: 115px;
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    ${({paddingRight}) => paddingRight ? `padding-right: ${paddingRight};` : ""}
    ${({color}) => color ? `color: ${color};` : ""}
    
    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        text-align: center;
        font-size: 14px;
        position: static;
        padding-right: 0px;    
    }

`
export const SegmentSubTitle = styled.h4`
    margin-top: 0px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-bottom: ${({marginBottom}) => marginBottom || "5px"};

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        text-align: center;
        font-size: 14px;
    }

`

const SegmentHeader = styled.header`
    width: ${({headerWidth}) => headerWidth || "30%"};
    position: relative;

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 100%;
    }
`

const SegmentContent = styled.div`
    width: ${({contentWidth}) => contentWidth || "70%"};
    text-align: left;

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 100%;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

const StyledSection = styled.section`
    display: flex;
    flex-direction: row;
    ${({paddingTop}) => paddingTop ? `padding-top: ${paddingTop};` : ""}
    ${({paddingBottom}) => paddingBottom ? `padding-bottom: ${paddingBottom};` : ""}

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        flex-direction: column;
    }
`


export default ({children, header, contentWidth, headerWidth, ...rest}) => (
    <StyledSection {...rest}>
        <SegmentHeader headerWidth={headerWidth}>{header}</SegmentHeader>
        <SegmentContent contentWidth={contentWidth}>{children}</SegmentContent>
    </StyledSection>
)