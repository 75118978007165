import styled from "styled-components"

export default styled.button`
    border-style: solid;
    border-color: ${({selected, theme}) => selected ? theme.colors.primary : theme.colors.inputBorder};
    border-radius: 5px;
    background-color: ${({selected, theme}) => selected ? theme.colors.selectedBackground : theme.colors.background};
    color: ${({selected, theme}) => selected ? theme.colors.primary : theme.colors.text};
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    height: ${({height}) => height || "50px"};
    width: 100%;
    ${({minWidth}) => minWidth ? `min-width: ${minWidth};` : ""}
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    
    outline: none;
    margin-left: ${({marginLeft}) => marginLeft || "0px"};
    margin-right: ${({marginRight}) => marginRight || "0px"};
    margin-top: ${({marginTop}) => marginTop || "0px"};
    margin-bottom: ${({marginBottom}) => marginBottom || "0px"};
    ${({paddingLeft}) => paddingLeft ? `padding-left: ${paddingLeft};` : ""}
    ${({paddingRight}) => paddingRight ? `padding-right: ${paddingRight};` : ""}
    padding-top: ${({paddingTop}) => paddingTop || "5px"};
    padding-bottom: ${({paddingBottom}) => paddingBottom || "5px"};

    transition: transform 400ms cubic-bezier(.455, .03, .515, .955), color 200ms cubic-bezier(.455, .03, .515, .955), 
        background-color 200ms cubic-bezier(.455, .03, .515, .955), box-shadow 200ms cubic-bezier(.455, .03, .515, .955);
    
    &:hover {
        box-shadow: ${({theme}) => theme.hoverShadow};
        transform: translate(0px, -2px);
    }

    &:disabled {
        background-color: ${({theme}) => theme.colors.disabledBackground};
        border-color: ${({theme}) => theme.colors.primaryExtraDisabled };
        color: ${({theme}) => theme.colors.disabledText};
        box-shadow: none;
        transform: translate(0px, 0px);
    }
`