import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import {useDispatch} from "react-redux"

import AppLayout from "../../components/applayout"
import SEO from "../../components/seo"
import Header from "../../components/header"
import TextInput, { getInputWithPrefix } from "../../components/textinput"
import ErrorMessage from "../../components/errormessage"
import Button, { ButtonWrapper } from "../../components/button"
import FlowContainer from "../../components/flowcontainer"
import CardRadioButton from "../../components/cardradiobutton"
import RadioButtonSet from "../../components/radiobuttonset"
import SubText from "../../components/subtext"
import Ellipsis from "../../components/ellipsis"
import FloatSegment from "../../components/floatsegment"
import Wrapper from "../../components/wrapper"

import blueWave from "../../images/bluewave.svg"

import { createWebsite } from "../../appstate/websites"
import { useAuth } from "../../services/auth"
import { useTheme } from "../../services/theme"
import { handleEventError } from "../../services/error"
import { setInputState, isWebsite, callThunk } from "../../services/helper"
import * as CONSTANTS from "../../services/constant"

const AddWebsite = () => {
    const [domain, setDomain] = useState("https://")
    //const [platform, setPlatform] = useState(CONSTANTS.WEBSITE_PLATFORM_WEBFLOW)
    const [stage, setStage] = useState(CONSTANTS.WEBSITE_STAGE_PRODUCTION)
    const [validationError, setValidationError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [submitting, setSubmitting] = useState(false)
    
    const user = useAuth().getUser()
    const theme = useTheme()
    const dispatch = useDispatch()
    
    const handleSubmit = async (e) => {        
        try {
            e.preventDefault()

            //remove trailing slash
            const websiteDomain = domain.endsWith('/') ? domain.slice(0,-1) : domain
            
            if(!isWebsite(websiteDomain.slice(8))){
                setErrorMessage("A valid website url is required.")
                setValidationError(true)
                return
            }

            //create website
            setSubmitting(true)
            const version = (new Date()).getTime()
            const response = await callThunk( dispatch, createWebsite, [ user, {
                domain: websiteDomain, 
                platform: CONSTANTS.WEBSITE_PLATFORM_WEBFLOW, 
                stage: stage, 
                version: version,
                life: CONSTANTS.CONFIG_FILE_CLIENT_CACHE_LIFE, 
                plan: CONSTANTS.CONFIG_FILE_FREE_PLAN_ID, 
                modules: {},
                sequences: {}, 
                forms: {}
            }])
            navigate(`/app/websites/${response.data.result[0].submissionId}/verifycode`)

        } catch (err) {
            setErrorMessage("Oops! Something's not right. We couldn't add that website. Please try again or reach out to customer support.")
            setValidationError(true)
            handleEventError(err, user, false)
        } finally {
            setSubmitting(false)
        }
    }

    //add suggestions via algolia or fuse.js + Grommet
    return (
        <AppLayout>
            <SEO title="Add Website" />
            <Wrapper
                backgroundImage={`url(${blueWave})`}
                backgroundSize={`100% 60%`}
                backgroundPosition={`center bottom`}
                backgroundRepeat={`no-repeat`}            
                minHeight="calc(100vh - 65px)"
                breakpoint={theme.breakpoints.mobile}
                responsiveStyle={`background: none;`}
            >
                <FlowContainer minHeight="calc(100vh - 65px)">
                    <FloatSegment 
                        marginLeft="auto" 
                        marginRight="auto" 
                        paddingTop="3%" 
                        paddingBottom="3%"  
                        shadow={true}
                    >
                        <Header 
                            title="Let's add your website."
                            subTitle="Tell us about the website that you would like to use with Vimkit."
                            paddingTop="0px"
                            paddingBottom="40px"
                        />
                        <TextInput 
                            placeholder="www.website.com" 
                            label="Website Domain"
                            type="text" maxWidth="400px" 
                            marginBottom="10px"
                            value={domain} 
                            onChange={e => setInputState(getInputWithPrefix("https://",e.target.value), setDomain, false, setValidationError)}
                            returnHandler={handleSubmit}
                            error={validationError ? true : false}
                            focus={true}
                        />
                        <SubText maxWidth="400px">
                            The domain of the website you’d like to add (e.g. www.example.com or blog.example.com). Don’t include folders or page paths.
                        </SubText>
                        {/*
                        <RadioButtonSet
                            label="PLATFORM"
                            marginBottom="15px"
                        >
                            <CardRadioButton
                                selected={platform === CONSTANTS.WEBSITE_PLATFORM_WEBFLOW} 
                                onClick={e => setInputState(CONSTANTS.WEBSITE_PLATFORM_WEBFLOW, setPlatform, false, setValidationError)}
                                marginLeft = "5px"
                                marginRight = "5px"
                            >
                                Webflow
                            </CardRadioButton>
                            <CardRadioButton
                                selected={platform === CONSTANTS.WEBSITE_PLATFORM_OTHER} 
                                onClick={e => setInputState(CONSTANTS.WEBSITE_PLATFORM_OTHER, setPlatform, false, setValidationError)}
                                marginLeft = "5px"
                                marginRight = "5px"
                            >
                                None
                            </CardRadioButton>
                        </RadioButtonSet> */}
                        <RadioButtonSet
                            label="Stage"
                            marginBottom="15px"
                            maxWidth="400px"
                        >
                            <CardRadioButton
                                selected={stage === CONSTANTS.WEBSITE_STAGE_PRODUCTION} 
                                onClick={e => setInputState(CONSTANTS.WEBSITE_STAGE_PRODUCTION, setStage, false, setValidationError)}
                                marginRight = "5px"
                                minWidth = "100px"
                            >
                                Production
                            </CardRadioButton>
                            <CardRadioButton
                                selected={stage === CONSTANTS.WEBSITE_STAGE_STAGING} 
                                onClick={e => setInputState(CONSTANTS.WEBSITE_STAGE_STAGING, setStage, false, setValidationError)}
                                marginLeft = "5px"
                                marginRight = "5px"
                                minWidth = "100px"
                            >
                                Staging
                            </CardRadioButton>
                            <CardRadioButton
                                selected={stage === CONSTANTS.WEBSITE_STAGE_DEVELOPMENT} 
                                onClick={e => setInputState(CONSTANTS.WEBSITE_STAGE_DEVELOPMENT, setStage, false, setValidationError)}
                                marginLeft = "5px"
                                minWidth = "100px"
                            >
                                Development
                            </CardRadioButton>
                        </RadioButtonSet>
                        
                        <ErrorMessage paddingBottom="15px">
                            {validationError? errorMessage : ""}
                        </ErrorMessage>
                        <ButtonWrapper>
                            <Link to="/app/websites">
                                <Button type="button" secondary="true" marginLeft="5px" marginRight="5px">Cancel</Button>
                            </Link>  
                            <Button type="button" marginLeft="5px" marginRight="5px" disabled={submitting} onClick={handleSubmit}>
                                {!submitting ? `Add Website` : <Ellipsis/>}
                            </Button>
                        </ButtonWrapper>
                    </FloatSegment>
                    
                </FlowContainer>
            </Wrapper>
            
        </AppLayout>
    )
}
export default AddWebsite