import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "gatsby"

import AppLayout from "../../components/applayout"
import SEO from "../../components/seo"
import Card, { CardPanel } from "../../components/card"
import ZeroState, { ZeroStateTitle } from "../../components/zerostate"
import Button, { ButtonWrapper } from "../../components/button"
import LoadState from "../../components/loadstate"
import WebsiteNav from "../../components/websitenav"
import { LeftNavSection } from "../../components/leftnav"
import Vector from "../../components/vector"
import Wrapper from "../../components/wrapper"
import ErrorState from "../../components/errorstate"

import hubspotLogo from "../../images/hubspotcard.svg"
import formZero from "../../images/formzero.svg"
import grayBlob from "../../images/grayblob.svg"

import { fetchWebsites } from "../../appstate/websites"
import { useAuth } from "../../services/auth"
import { useTheme } from "../../services/theme"
import { handleEventError } from "../../services/error"
import { callThunk } from "../../services/helper"

const FormGallery = ({websiteId}) => {
    const dispatch = useDispatch()
    const website = useSelector(state => state.websites.find(website => website.submissionId === websiteId))
    const forms = (website && website.submissionData.forms) ? Object.values(website.submissionData.forms) : []
    const domain = website ? website.submissionData.domain.slice(8) : ''
    const stage = website ? website.submissionData.stage : ''
    const user = useAuth().getUser()
    const theme = useTheme()

    const [loading, setLoading] = useState(false)
    const [loadError, setLoadError] = useState(false)

    useEffect(() => {
        if(!user || website) {
            return
        }

        let unmounted = false
        const loadWebsites = async () => {
            try {
                setLoading(true)
                await callThunk(dispatch, fetchWebsites, [user])
            } catch (err) {
                handleEventError(err, user, false)
                if(!unmounted) {
                    setLoadError(true)
                }
            } finally {
                if(!unmounted) {
                    setLoading(false)
                }
            }
        }
        loadWebsites()
        return () => unmounted = true
    },[user, website, dispatch])

    return (
        <AppLayout
            contextTitle={domain}
            contextCategory={stage}
            ctaPath={`/app/websites/${websiteId}/forms/create`}
            ctaContent="Add Form"
        >
            <SEO title="Website Forms" />    
            {
                !loadError &&
                <>
                    <WebsiteNav websiteId={websiteId} />
                    <Wrapper         
                        backgroundImage={`url(${grayBlob})`}
                        backgroundSize={`auto 100%`}
                        backgroundPosition={`bottom right`}
                        backgroundRepeat={`no-repeat`}
                        minHeight="calc(100vh - 65px)"
                    >
                        <LeftNavSection first="true">
                            <CardPanel>
                                {forms.map(form =>
                                    form &&  
                                    <Card
                                        key={form.formId}
                                        to={`/app/websites/${website.submissionId}/forms/${form.formKey}`}
                                        src={hubspotLogo}
                                        title={form.name}
                                        callToAction="Edit &#8594;"
                                    >
                                    </Card>                              
                                )}
                            </CardPanel>
                            <ZeroState 
                                show={!loading && (!forms || forms.length === 0)} 
                                breakpoint={theme.breakpoints.tablet} 
                                responsiveStyle="padding-left: 5%; padding-right: 5%;"
                            >
                                <ZeroStateTitle maxWidth="480px">No forms on your website ...the sadness! Let's add one now.</ZeroStateTitle>
                                <ButtonWrapper paddingTop="5px" paddingBottom="20px">
                                    <Link to={`/app/websites/${websiteId}/forms/create`}>
                                        <Button type="button" marginLeft="5px" marginRight="5px">Add Form</Button>
                                    </Link>  
                                </ButtonWrapper>
                                <Vector src={formZero} paddingTop="3%" maxWidth="240px"/>
                            </ZeroState> 
                            <LoadState 
                                spinnerSize={(forms && forms.length !== 0) ? "15px" : "40px"}
                                height={(forms && forms.length !== 0) ? "30px" : "300px"}  
                                show={loading} 
                            />
                        </LeftNavSection>
                    </Wrapper>
                </>
            }
            <ErrorState
                show={loadError}
                paddingTop="10%" 
                breakpoint={theme.breakpoints.tablet} 
                responsiveStyle="padding-left: 10%; padding-right: 10%;"
                title="Oops! Couldn't load your forms."
            >
                Something's not right. Try refreshing the page or reach out to customer support.
            </ErrorState>
        </AppLayout>
    )
}
export default FormGallery

