import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"

import TextInput, { getInputWithPrefix } from "../components/textinput"
import Button, { ButtonWrapper } from "../components/button"
import EmbedCode, { EmbedTitle, EmbedStep, EmbedDescription} from "../components/embedcode"
import ErrorMessage from "../components/errormessage"
import Ellipsis from "../components/ellipsis"
import TextDiv from "../components/textdiv"
import SubText from "../components/subtext"
import LoadState from "../components/loadstate"
import ExternalLink from "../components/externallink"

import { updateFormInstallStatus } from "../appstate/websites"
import { useAuth } from "../services/auth"
import { useTheme } from "../services/theme"
import { callApi } from "../services/api"
import { callThunk, setInputState } from "../services/helper"
import { handleEventError } from "../services/error"

const checkAttribute = (source, sequenceKey, pageUrl) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(source, `text/html`)
    const form = doc.querySelector(`form[data-vimkit-success="${sequenceKey}"]`)
    if(form) {
        return {installStatus: true}
    } else {
        const attribute = doc.querySelector(`[data-vimkit-success="${sequenceKey}"]`)
        const error = attribute ? `Oops! The custom attribute is not on a form element`+
        `. Add the custom attribute to a form element and try again.` : 
        `Oops! Couldn't find the custom attribute on ${pageUrl}`+
        `. Check if the custom attribute has been added, publish the site and try again.`
        return {installStatus: false, error}
    }
}

const VerifyForm = ({currentWebsite, currentForm, successRedirectPath, align, backgroundColor, color, borderColor, placeholderColor, children}) => {

    const [pageUrl, setPageUrl] = useState('')
    const [validationError, setValidationError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [verifying, setVerifying] = useState(false)

    const auth = useAuth()
    const user = auth.getUser() 
    const theme = useTheme()
    const dispatch = useDispatch()

    const websiteId = currentWebsite ? currentWebsite.submissionId : ''
    const domain = currentWebsite ? currentWebsite.submissionData.domain : ''
    const sequenceKey = currentForm && currentForm.sequenceKeys ? currentForm.sequenceKeys[0] : ''

    useEffect(() => {
        if(!domain){
            return
        }
        setPageUrl(domain)
    }, [domain])
    
    const verifyForm = async (e) => {
        try {
            e.preventDefault()

            setVerifying(true)
            const response = await callApi(user, {
                method: 'get',
                url:'/web',
                params: {
                    url: encodeURIComponent(pageUrl)
                }
            })

            if(response.status !== 200 || !response.data || !response.data.result){
                setErrorMessage(`Oops! Can't access ${pageUrl}`+
                `. Check if the website is published and try again.`)
                setValidationError(true)
                return
            }
            
            const { installStatus, error} = checkAttribute(response.data.result, sequenceKey, pageUrl)
            await callThunk(dispatch, updateFormInstallStatus, [user, websiteId, currentForm.formKey, installStatus])

            if(!installStatus){
                setErrorMessage(error)    
                setValidationError(true)
                return
            }
            navigate(`/app/celebrate`, { state: { redirectPath: successRedirectPath } })

        } catch (err) {
            setErrorMessage(`Oops! Something's not right. We couldn't verify the custom attribute on ${pageUrl}.`+
            ` Please try again or reach out to customer support.`)
            setValidationError(true)
            handleEventError(err, user, false)

        } finally {
            setVerifying(false)
        }
                
    }

    if(!currentWebsite || !currentForm) {
        return (
            <LoadState 
                height="300px" 
                width="60px"
                spinnerSize="40px"
                show={true}
            />
        )    
    }

    return (
        <>
            <EmbedStep marginBottom="40px">
                <EmbedTitle align="left" responsiveAlign="center" breakpoint={theme.breakpoints.mobile}>
                    Add the custom attribute on Webflow
                </EmbedTitle>

                <EmbedDescription align={align} responsiveAlign="center" breakpoint={theme.breakpoints.mobile}>
                    Before you can start using the form, you need to add a custom attribute on Webflow with the name and value provided below.
                </EmbedDescription>
                <EmbedCode
                    label="Custom Attribute Name"
                    height="55px"
                    marginBottom="15px"
                >
                    data-vimkit-success
                </EmbedCode>
                <EmbedCode
                    label="Custom Attribute Value"
                    height="55px"
                    marginBottom="15px"
                >
                    {sequenceKey}
                </EmbedCode>
                <SubText align="left">
                    Not sure how to add a custom attribute? See
                    <ExternalLink 
                        display="inline" 
                        fontWeight="600"
                        to="https://university.webflow.com/article/how-to-add-custom-attributes-to-an-element-in-webflow#custom-attributes" >
                            &nbsp;Webflow's documentation
                    </ExternalLink>.
                </SubText>

            </EmbedStep>
            <EmbedStep>
                <EmbedTitle align="left" responsiveAlign="center" breakpoint={theme.breakpoints.mobile}>
                    Check if the custom attribute is working
                </EmbedTitle>
                <TextDiv 
                    maxWidth="540px"
                    marginBottom="20px"
                    align={align}
                    breakpoint={theme.breakpoints.mobile}
                    responsiveAlign="center"
                >
                    After you've added the custom attribute to the form on Webflow,
                     type the full URL of the page where you're using this form and click verify. 
                </TextDiv>
                <TextInput 
                    label="Page URL"
                    type="text" 
                    maxWidth="540px" 
                    marginLeft="0px"
                    marginRight="0px"
                    marginBottom="10px"
                    value={pageUrl} 
                    onChange={e => setInputState(getInputWithPrefix(domain,e.target.value), setPageUrl, false, setValidationError)}
                    returnHandler={verifyForm}
                    error={validationError ? true : false}
                    color={color}
                    backgroundColor={backgroundColor}
                    borderColor={borderColor}
                    placeholderColor={placeholderColor}
                />
                <SubText align="left">
                    Make sure you've published your website after adding the custom form attribute.
                </SubText>
            </EmbedStep>
            <ErrorMessage 
                maxWidth="540px" 
                align={align}
                breakpoint={theme.breakpoints.mobile}
                responsiveAlign="center"
            >
                {validationError? errorMessage : ""}
            </ErrorMessage>
            <ButtonWrapper align={align} maxWidth="540px">
                { children }                
                <Button type="button" disabled={verifying} onClick={verifyForm}>
                    {!verifying ? `Verify` : <Ellipsis/>}
                </Button>
            </ButtonWrapper>
        </>
    )
}
export default VerifyForm