import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { handleEventError } from "../services/error"

const StyledTextArea = styled.textarea`
    border-style: none;
    outline: none;
    font-size: ${({fontSize}) => fontSize || "14px" };
    color: ${({error, fontColor, theme}) => error ? theme.colors.error : fontColor || theme.colors.text };
    width: 100%;
    height: 80px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 0px;
    overflow: auto;
    resize: none;
      
    ::placeholder {
        color: ${({error, theme}) => error ? theme.colors.error : theme.colors.placeholderText };
        font-size: ${({fontSize}) => fontSize || "12px" };
    }

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 16px;
    }
`
const StyledDiv = styled.div`
    position: relative;
    display: ${({display}) => display || "inline-block"};
    text-align: left;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: ${({error, theme}) => error ? theme.colors.error : theme.colors.inputBorder};
    ${({marginTop}) => marginTop ? `margin-top: ${marginTop};` : ""}
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    ${({marginLeft}) => marginLeft ? `margin-left: ${marginLeft};` : ""}
    ${({marginRight}) => marginRight ? `margin-right: ${marginRight};` : ""}

    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;

    width: 100%; 
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}

`
const Label = styled.span`
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    background-color: ${({theme}) => theme.colors.background};
    position: absolute;
    top: -10px;
    left: 20px;
    color: ${({error, theme}) => error ? theme.colors.error : theme.colors.inputBorder};
    font-size: 12px;
`

export const getInputWithPrefix = (prefix, value) => {
    try {
        const trimmedValue = value.trim()
        const length = prefix.length
        if (length > trimmedValue.length) {
            return prefix
        } else if (prefix === trimmedValue.slice(0,length)) {
            //check if prefix is repeated
            if(prefix === trimmedValue.slice(length,length*2)){
                return trimmedValue.slice(length)
            }
            return value
        } else {
            //prefix was deleted
            return prefix + trimmedValue.slice(length-1)
            //TODO:handle case where multiple characters deleted in prefix?
        }
    } catch (err) {
        handleEventError(err, false, false)
    }

}

const TextArea = ({returnHandler, focus, error, label, type, placeholder, value, fontSize, fontColor, onChange, ...rest}) => {
    const input = useRef()

    const handleReturn = e => {
        try {
            if(e.keyCode === 13) {
                e.preventDefault()
                returnHandler(e)
            }
        } catch (err) {
            handleEventError(err, false, false)
        }
    }

    useEffect(() => {
        if (focus){
            input.current.focus()
        }
    },[focus])

    return (
        <StyledDiv error={error} {...rest}>
            <Label error={error} >{label}</Label>
            <StyledTextArea 
                ref={input}
                type={type} 
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyUp={handleReturn}
                error={error}
                fontSize={fontSize}
                fontColor={fontColor}
            />
        </StyledDiv>
    )
}

export default TextArea