import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import Button, { ButtonWrapper } from "../components/button"
import EmbedCode, { EmbedStep, EmbedDescription} from "../components/embedcode"
import ErrorMessage from "../components/errormessage"
import Ellipsis from "../components/ellipsis"
import LoadState from "../components/loadstate"
import ExternalLink from "../components/externallink"

import { updateWebsiteInstallStatus } from "../appstate/websites"
import { useAuth } from "../services/auth"
import { useTheme } from "../services/theme"
import { callApi } from "../services/api"
import { callThunk } from "../services/helper"
import { handleEventError } from "../services/error"

const buildCode = (websiteId, userPrefixKey, originPrefixKey) => {
    return `<script>!function(){if(!window.vimkit){`+
    `var m=([].flat&&window.onunhandledrejection!==undefined&&window.IntersectionObserver)?'-modern':'';var s=document.createElement('script');`+
    `s.type='text/javascript';s.async=!0;`+
    `var e=document.getElementsByTagName('script')[0];e.parentNode.insertBefore(s,e);s.onload=function(){`+
    `vimkit.load('${websiteId}', '${process.env.GATSBY_CONFIG_BASE_URL}/${originPrefixKey}/${userPrefixKey}');};`+
    `s.src='${process.env.GATSBY_SCRIPT_BASE_URL}/static/js/core'+m+'.min.js';}}();</script>`
}

const getOgImageUrl = source => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(source, `text/html`)
    const tag = doc.querySelector(`meta[property='og:image']`)
    return tag ? tag.getAttribute(`content`) : null
}

const VerifyWebsite = ({currentWebsite, successRedirectPath, align, children}) => {
    const [snippet, setSnippet] = useState("")
    const [validationError, setValidationError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [verifying, setVerifying] = useState(false)

    const auth = useAuth()
    const user = auth.getUser() 
    const theme = useTheme()
    const dispatch = useDispatch()
    const permissions = useSelector(state => state.permissions)

    const websiteId = currentWebsite ? currentWebsite.submissionId : ''
    const domain = currentWebsite ? currentWebsite.submissionData.domain : ''
    const domainDisplay = currentWebsite ? domain.slice(8) : ''

    useEffect(() => {
        if(!user || !permissions){
            return 
        }
        const { userPrefixKey, originPrefixKey } = permissions
        setSnippet(buildCode(websiteId, userPrefixKey, originPrefixKey))
    },[user, permissions, websiteId])

    const verifyCode = async (e) => {
        try {
            e.preventDefault()

            setVerifying(true)
            const response = await callApi(user, {
                method: 'get',
                url:'/web',
                params: {
                    url: encodeURIComponent(domain)
                }
            })

            if(response.status !== 200 || !response.data || !response.data.result){
                setErrorMessage(`Can't access ${domainDisplay}`+
                `. Check if the website is published and try again.`)
                setValidationError(true)
                return
            }
            const ogImageUrl = getOgImageUrl(response.data.result)
            const installStatus = response.data.result.includes(snippet)
            await callThunk(dispatch, updateWebsiteInstallStatus, [user, websiteId, installStatus, ogImageUrl])

            if(!installStatus){

                setErrorMessage(`Can't find the code snippet on ${domainDisplay}`+
                `. Remember to publish the website after adding the code snippet.`)
                setValidationError(true)
                return
            } 

            navigate(`/app/celebrate`, { state: { redirectPath: successRedirectPath } })

        } catch (err) {
            setErrorMessage(`Couldn't verify the code snippet on ${domainDisplay}.`+
            ` Please try again or reach out to customer support.`)
            setValidationError(true)
            handleEventError(err, user, false)
        } finally {
            setVerifying(false)
        }
                
    }

    if(!currentWebsite) {
        return (
            <LoadState 
                height="240px" 
                width="60px"
                spinnerSize="40px"
                show={true}
            />
        )    
    }

    return (
        <>
            <EmbedStep>
                <EmbedDescription align={align} responsiveAlign="center" breakpoint={theme.breakpoints.mobile}>
                    Copy this code snippet and add it to the Head Code of {domainDisplay}. See this&nbsp;
                    <ExternalLink 
                        to="https://university.webflow.com/article/how-to-add-custom-head-and-body-code-to-a-webflow-site#custom-code-in-your-project-settings" 
                        display="inline" 
                        fontWeight="600"
                    >
                        Webflow tutorial
                    </ExternalLink>
                    &nbsp;if you need help adding custom code.&nbsp;
                    Save changes and publish your website after you've added the custom code!</EmbedDescription>
                <EmbedCode
                    label="Website Code Snippet"
                    height="85px"
                >
                    {`<!-- Vimkit Code Snippet -->
`+snippet+`
<!-- End Vimkit Code Snippet -->`}
                </EmbedCode>
            </EmbedStep>
            <ErrorMessage 
                maxWidth="540px" 
                align={align}
                breakpoint={theme.breakpoints.mobile}
                responsiveAlign="center"
            >
                {validationError? errorMessage : ""}
            </ErrorMessage>
            <ButtonWrapper align={align} maxWidth="540px">
                { children }                
                <Button type="button" disabled={verifying} onClick={verifyCode}>
                    {!verifying ? `Verify` : <Ellipsis/>}
                </Button>
            </ButtonWrapper>
        </>
    )
}
export default VerifyWebsite