import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Button from "./button"
import { limitString } from "../services/helper"

const CtaLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
`

export const NavContextCta = ({to, children, ...rest}) => (
    <CtaLink to={to}>
        <Button {...rest} >{children}</Button>
    </CtaLink>
)

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
`

const Category = styled.div`
    padding-top: 2px;
    padding-bottom: 3px;
    color: ${({theme}) => theme.colors.subtleText};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
`

const Title = styled.h3`
    padding-top: 1px;
    padding-bottom: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 18px;

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        font-size: 14px;
    }
`

export default ({category, title, limit, ...rest}) => (
        <StyledWrapper {...rest}>
            <Category>{category}</Category>
            <Title>{limitString(title, limit)}</Title>
        </StyledWrapper>
)