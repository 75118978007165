import React, {useEffect, useState} from "react"
import {useSelector, useDispatch} from "react-redux"
import { Link } from "gatsby"

import AppLayout from "../../components/applayout"
import SEO from "../../components/seo"
import Card, { CardPanel } from "../../components/card"
import Container from "../../components/container"
import ZeroState, {ZeroStateTitle} from "../../components/zerostate"
import LoadState from "../../components/loadstate"
import TextSpan from "../../components/textspan"
import Vector from "../../components/vector"
import Wrapper from "../../components/wrapper"
import Button, {ButtonWrapper} from "../../components/button"
import ErrorState from "../../components/errorstate"

import { fetchWebsites } from "../../appstate/websites"
import { useAuth } from "../../services/auth"
import { useTheme } from "../../services/theme"
import { callThunk } from "../../services/helper"
import { handleEventError } from "../../services/error"

import defaultWebsiteCard from "../../images/websitecard.svg"
import grayBlob from "../../images/grayblob.svg"
import websiteZero from "../../images/websitezero.svg"

const WebsiteGallery = () => {
    const dispatch = useDispatch()
    const websites = useSelector(state => state.websites)
    const user = useAuth().getUser()
    const theme = useTheme()
    const [loading, setLoading] = useState(true)
    const [loadError, setLoadError] = useState(false)

    useEffect(() => {
        if(!loading){
            return
        }

        if(websites.length !==0) {
            setLoading(false)
            return
        }

        if(!user) {
            return
        }
        
        let unmounted = false
        const loadWebsites = async () => {
            try {
                await callThunk(dispatch, fetchWebsites, [user])
            } catch (err) {
                handleEventError(err, user, false)
                if(!unmounted) {
                    setLoadError(true)
                }
            } finally {
                if(!unmounted) {
                    setLoading(false)
                }
            }
        }
        loadWebsites()
        return () => unmounted = true
        
    },[user, dispatch, websites.length, loading])

    return (
        <AppLayout
            ctaPath="/app/websites/create"
            ctaContent="Add Website"
            contextTitle={(user.displayName ? (user.displayName.split(" ")[0] + "'s websites"): "All websites")}
        >
            <SEO title="My Websites" />
            <Wrapper         
                backgroundImage={`url(${grayBlob})`}
                backgroundSize={`auto 100%`}
                backgroundPosition={`bottom right`}
                backgroundRepeat={`no-repeat`}
                minHeight="calc(100vh - 65px)"
            >
                <Container>
                    {
                        !loadError &&
                        <CardPanel 
                            marginTop={(!loading && (!websites || websites.length === 0)) ? "0px" : "50px"} 
                            marginBottom={(!loading && (!websites || websites.length === 0)) ? "0px" : "50px"} 
                        >
                            {websites.map(website => 
                                <Card
                                    key={website.submissionId}
                                    to={`/app/websites/${website.submissionId}/forms`}
                                    category={website.submissionData.stage}
                                    title={website.submissionData.domain ? website.submissionData.domain.slice(8) : ""}
                                    callToAction="Open &#8594;"
                                    src={website.submissionData.ogImageUrl || defaultWebsiteCard}
                                >
                                    {
                                        website.submissionData.installStatus ? 
                                            <TextSpan color={theme.colors.success}>&#x2714;&nbsp;Verified</TextSpan> : 
                                            <TextSpan color={theme.colors.error}>&#x2718;&nbsp;Unverified</TextSpan>
                                    }
                                </Card>  
                            )}
                        </CardPanel>
                    }
                    <ZeroState 
                        show={!loading && (!websites || websites.length === 0) && !loadError} 
                        paddingTop="10%" 
                        breakpoint={theme.breakpoints.tablet} 
                        responsiveStyle="padding-left: 10%; padding-right: 10%;"
                    >
                        <ZeroStateTitle maxWidth="400px">Hmmm ...it's kinda empty in here. Let's add your first website.</ZeroStateTitle>
                        <ButtonWrapper paddingTop="10px" paddingBottom="30px">
                            <Link to="/app/websites/create">
                                <Button type="button" marginLeft="5px" marginRight="5px">Add Website</Button>
                            </Link>  
                        </ButtonWrapper>
                        <Vector src={websiteZero} paddingTop="3%" maxWidth="250px"/>
                    </ZeroState>
                    <LoadState 
                        spinnerSize={(websites && websites.length !== 0) ? "15px" : "40px"}
                        height={(websites && websites.length !== 0) ? "30px" : "300px"}  
                        show={loading} 
                    />
                    <ErrorState
                        show={loadError}
                        paddingTop="10%" 
                        breakpoint={theme.breakpoints.tablet} 
                        responsiveStyle="padding-left: 10%; padding-right: 10%;"
                        title="Oops! Couldn't load your websites."
                    >
                        Something's not right. Try refreshing the page or reach out to customer support.
                    </ErrorState>
                </Container>
            </Wrapper>
            
        </AppLayout>
    )
}
export default WebsiteGallery