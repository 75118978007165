import React from "react"
import styled from "styled-components"

const StyledTitle = styled.div`
    color: ${({theme}) => theme.colors.primary};
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding-bottom: 5px;
`

const StyledSubTitle = styled.div`
    text-align: left;
`

const StyledImg = styled.img`
    max-width: 50px;
`

const StyledWrapper = styled.div`
    margin-right: 20px;
`

const StyledButton = styled.button`
    display: ${({display}) => display || "flex"};
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-style: solid;
    border-color: ${({theme}) => theme.colors.primary};
    border-radius: 5px;
    background-color: ${({theme}) => theme.colors.background};
    cursor: pointer;
    text-decoration: none;
    height: ${({height}) => height || "auto"};
    width: 100%;
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    
    outline: none;
    margin-left: ${({marginLeft}) => marginLeft || "0px"};
    margin-right: ${({marginRight}) => marginRight || "0px"};
    margin-top: ${({marginTop}) => marginTop || "0px"};
    margin-bottom: ${({marginBottom}) => marginBottom || "0px"};

    transition: transform 400ms cubic-bezier(.455, .03, .515, .955), color 200ms cubic-bezier(.455, .03, .515, .955),
     background-color 200ms cubic-bezier(.455, .03, .515, .955),
     box-shadow 200ms cubic-bezier(.455, .03, .515, .955);
    
    &:hover {
        box-shadow: ${({theme}) => theme.hoverShadow};
        transform: translate(0px, -2px);
    }
`
const CardButton = ({title, subTitle, src, ...rest}) => {
    return (
        <StyledButton {...rest}>
            <StyledWrapper>
                <StyledTitle>{title}</StyledTitle>
                <StyledSubTitle>{subTitle}</StyledSubTitle>
            </StyledWrapper>
            <StyledImg src={src}></StyledImg>
        </StyledButton>
    )
}

export default CardButton