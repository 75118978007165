import React from "react"
import styled from "styled-components"
import Ellipsis from "./ellipsis"

export const ButtonList = styled.ul`
    color: ${({theme}) => theme.colors.text};
    width: 100%;
    ${({display}) => display ? `display: ${display};` : ""}
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}

	box-sizing: border-box;
    list-style-type: none;
    padding-left: ${({paddingLeft}) => paddingLeft || "0px"};
    margin-top: ${({marginTop}) => marginTop || "0px"};
    margin-bottom: ${({marginBottom}) => marginBottom || "0px"};
`

const StyledItem = styled.li`
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
`

const StyledButton = styled.button`
    padding: 0px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    border: 0px;
    font-size: 14px;
    background-color: ${({theme}) => theme.colors.background};
    justify-content: space-between;
    align-items: stretch;
    box-shadow: ${({theme}) => theme.shadow};
    transition: transform 400ms cubic-bezier(.455, .03, .515, .955), box-shadow 200ms cubic-bezier(.455, .03, .515, .955);


    &: hover {   
        transform: translate(0px, -2px);        
        box-shadow: ${({theme}) => theme.hoverShadow};
    }

    &:disabled {
        box-shadow: ${({wait, theme}) => wait ? theme.hoverShadow : theme.shadow};
        transform: translate(0px, 0px);
    }
`

const CallToAction = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 125px;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: ${({disabled, theme}) => disabled ? theme.colors.disabledText : theme.colors.primary};
    background-color: ${({wait, theme}) => wait ? theme.colors.primary : theme.colors.background};

    transition: background-color 200ms cubic-bezier(.455, .03, .515, .955), color 200ms cubic-bezier(.455, .03, .515, .955);

    ${StyledItem}:hover & {
        background-color: ${({disabled, wait, theme}) => (disabled && !wait) ? theme.colors.background : theme.colors.primary};
        color: ${({disabled, wait, theme}) => (disabled && !wait) ? theme.colors.disabledText : theme.colors.inverseText};
    }
`
const VerticalAlignWrap = styled.div`
    display: block;
`

const StyledContent = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
`

const ButtonListItem = ({disabled, wait, children, callToAction, ...rest}) => (
    <StyledItem {...rest} >
        <StyledButton disabled={disabled} wait={wait}>
            <StyledContent>{children}</StyledContent>
            <CallToAction wait={wait} disabled={disabled}>
                <VerticalAlignWrap>{wait ? <Ellipsis/> : callToAction}</VerticalAlignWrap>
            </CallToAction>
        </StyledButton>
    </StyledItem>
)
export default ButtonListItem