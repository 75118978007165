import React from "react"
import styled from "styled-components"

import Vector from "./vector"

import errorDoodle from "../images/errordoodle.svg"

const ErrorStateTitle = styled.h3`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 10px;
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 20px;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        font-size: 16px;
    }
`

const StyledDiv = styled.div`
    position: relative;
    display: ${({show}) => show ? "flex" : "none"};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    ${({minWidth}) => minWidth ? `min-width: ${minWidth};` : ""}
    ${({paddingTop}) => paddingTop ? `padding-top: ${paddingTop};` : ""}
    ${({paddingBottom}) => paddingBottom ? `padding-bottom: ${paddingBottom};` : ""}
    ${({paddingLeft}) => paddingLeft ? `padding-left: ${paddingLeft};` : ""}
    ${({paddingRight}) => paddingRight ? `padding-right: ${paddingRight};` : ""}

    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {${responsiveStyle}}` : ""}
`

export default ({children, title, ...rest}) => (
    <StyledDiv {...rest}>
        <ErrorStateTitle maxWidth="600px">{title}</ErrorStateTitle>
        {children}
        <Vector src={errorDoodle} paddingTop="3%" maxWidth="250px"/>
    </StyledDiv>
)