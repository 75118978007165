import styled from "styled-components"
import {Link} from "gatsby"

const HelpLink = styled(Link)`
    display: ${({display}) => display || "block"};
    margin-top: 15px;
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
    text-align: ${({align}) => align || "center"};
    font-weight: ${({fontWeight}) => fontWeight || "400"};
`
export default HelpLink