import React, { useState } from "react"

import AppLayout from "../../components/applayout"
import SEO from "../../components/seo"
//import Segment, {SegmentSubTitle, SegmentTitle, SegmentText} from "../../components/segment"
import Segment, {SegmentTitle, SegmentText} from "../../components/segment"
import HubspotButton from "../../components/hubspotbutton"
import ErrorMessage from "../../components/errormessage"
import Highlighter from "../../components/highlighter"
import Subtext from "../../components/subtext"
import Section from "../../components/section"
import FlexColumnWrapper from "../../components/flexcolumnwrapper"

import { useTheme } from "../../services/theme"

const AddIntegration = ({location}) => {

    const theme = useTheme()
    const [validationError, setValidationError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const redirectPath = (location && location.state && location.state.redirectPath) ? location.state.redirectPath : `/app/integrations`
    return (
        <AppLayout
            ctaPath={`/help/guides/hubspot-webflow-form-tool#setupform`}
            ctaContent="See Guide"
            contextTitle="Add integration"
        >
            <SEO title="Add Integration" />
            <Section 
                paddingBottom="60px" 
                paddingTop="60px"
            >
                <Segment header={<SegmentTitle>Connect Vimkit to other platforms</SegmentTitle>}>
                    {/*
                        <SegmentSubTitle marginBottom="15px">Choose the platform you'd like to connect to</SegmentSubTitle>
                    */}
                    <HubspotButton
                        show={true}
                        redirectPath={redirectPath}
                        setValidationError={setValidationError}
                        setErrorMessage={setErrorMessage}
                    />
                    <FlexColumnWrapper 
                        maxWidth="580px"
                        breakpoint={theme.breakpoints.mobile} 
                        responsiveStyle="text-align: center;"
                    >
                        <SegmentText marginTop="0px" marginBottom="20px">
                            Make sure you <Highlighter>sign out on Hubspot</Highlighter> if you are adding multiple Hubspot accounts.
                            <Subtext align="left" responsiveAlign="center">
                                For example, if you've already connected Vimkit to Hubspot Account A,
                                make sure you've signed out on Hubspot from Account A before you connect Vimkit to Hubspot Account B. 
                            </Subtext>  
                        </SegmentText>

                        <ErrorMessage paddingBottom="15px">
                            &nbsp;{validationError? errorMessage : ""}&nbsp;
                        </ErrorMessage>
                    </FlexColumnWrapper>

                </Segment>
            </Section>
        </AppLayout>
    )
}
export default AddIntegration