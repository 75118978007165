import React from "react"
import { Router } from "@reach/router"

import ProtectedRoute from "../components/protectedroute"
import Login from "../approutes/login"
import Logout from "../approutes/logout"
import Account from "../approutes/account"
import DeleteAccount from "../approutes/deleteaccount"
import AppGallery from "../approutes/appgallery"
import WebsiteGallery from "../approutes/websites/websitegallery"
import AddWebsite from "../approutes/websites/addwebsite"
import WebsiteDashboard from "../approutes/websites/websitedashboard"
import WebsiteSettings from "../approutes/websites/websitesettings"
import FormGallery from "../approutes/websiteforms/formgallery"
import AddForm from "../approutes/websiteforms/addform"
import AddFormWithCode from "../approutes/websiteforms/addformwithcode"
import AddFormProvider from "../approutes/websiteforms/addformprovider"
import FormSettings from "../approutes/websiteforms/formsettings"
import AddFormAttribute from "../approutes/websiteforms/addformattribute"
import IntegrationGallery from "../approutes/integrations/integrationgallery"
import IntegrationSettings from "../approutes/integrations/integrationsettings"
import AddIntegration from "../approutes/integrations/addintegration"
import Authorize from "../approutes/authorize"
import SuccessSplash from "../approutes/successsplash"
import InstallCode from "../approutes/websites/installcode"
import SelectPersona from "../approutes/onboard/selectpersona"
import SelectIntent from "../approutes/onboard/selectintent"
import SeeOverview from "../approutes/onboard/seeoverview"

const App = () => (
    <Router basepath="/app">
      <Login path="/login" />
      <Authorize path="/authorize"/>
      <Logout path="/logout"/>

      <ProtectedRoute path="/" component={AppGallery}/>
      <ProtectedRoute path="/account" component={Account}/>
      <ProtectedRoute path="/delete" component={DeleteAccount}/>
      <ProtectedRoute path="/celebrate" component={SuccessSplash}/>
      
      <ProtectedRoute path="/setup/audience" component={SelectPersona}/>
      <ProtectedRoute path="/setup/personalize" component={SelectIntent}/>
      <ProtectedRoute path="/setup/overview" component={SeeOverview}/>

      <ProtectedRoute path="/websites" component={WebsiteGallery}/>
      <ProtectedRoute path="/websites/create" component={AddWebsite}/>
      <ProtectedRoute path="/websites/:websiteId" component={WebsiteDashboard}/>
      <ProtectedRoute path="/websites/:websiteId/settings" component={WebsiteSettings}/>
      <ProtectedRoute path="/websites/:websiteId/verifycode" component={InstallCode}/>

      <ProtectedRoute path="/websites/:websiteId/forms" component={FormGallery}/>
      <ProtectedRoute path="/websites/:websiteId/forms/create" component={AddForm}/>
      <ProtectedRoute path="/websites/:websiteId/forms/createmanual" component={AddFormWithCode}/>
      <ProtectedRoute path="/websites/:websiteId/forms/connect" component={AddFormProvider}/>
      <ProtectedRoute path="/websites/:websiteId/forms/:formKey" component={FormSettings}/>
      <ProtectedRoute path="/websites/:websiteId/forms/:formKey/addattribute" component={AddFormAttribute}/>

      <ProtectedRoute path="/integrations" component={IntegrationGallery}/>
      <ProtectedRoute path="/integrations/:integrationId" component={IntegrationSettings}/>
      <ProtectedRoute path="/integrations/create" component={AddIntegration}/>

    </Router>
)

export default App
