import React from "react"
import { navigate } from "gatsby"
import { useAuth } from "../services/auth"
import { isBrowser } from "../services/helper"

const ProtectedRoute = ({ component: Component, location, ...rest }) => {
  const auth = useAuth()
  if (isBrowser() && !(auth.isLoggedIn()) && location.pathname !== `/app/login`) {
    navigate("/app/login", { state: { redirectPath: location.pathname } })
    return null
  }
  return <Component {...rest} location={location}/>
}
export default ProtectedRoute