import React,{ useState, useEffect } from "react"
import {navigate} from "gatsby"

import Lottie from "../components/lottie"
import Confetti from "../components/confetti"
import FlexColumnWrapper from "../components/flexcolumnwrapper"
import TextDiv from "../components/textdiv"
import FadeWrapper from "../components/fadewrapper"
import { useTheme } from "../services/theme"
import { handleEventError } from "../services/error"

import success from "../lotties/success.json"

const SuccessSplash = ({location}) => {
    const [showConfetti, setShowConfetti] = useState(false)
    const [playLottie, setPlayLottie] = useState(false)

    const theme = useTheme()
    const config = {
        angle: 90,
        spread: "200",
        startVelocity: "65",
        elementCount: "200",
        dragFriction: 0.1,
        duration: 3000,
        stagger: 0,
        width: "10px",
        height: "10px",
        colors: [theme.colors.primary, theme.colors.secondary, theme.colors.success, theme.colors.error, theme.colors.accent]
    }

    const afterConfetti = () => {
        try {
            setShowConfetti(false)
            navigate(location.state.redirectPath, { replace: true})
    
        } catch (err) {
            handleEventError(err, false, false)
        }
    }

    const afterLottie = () => {
        try {
            setShowConfetti(true)  
            setPlayLottie(false)
        } catch (err) {
            handleEventError(err, false, false)
        }
    }

    useEffect(() => {
        setPlayLottie(true)
    },[])

    return (
        <FlexColumnWrapper verticalAlign="center" horizontalAlign="center" minHeight="100vh" minWidth="100vw">
            <Confetti config={config} onCompletion={afterConfetti} show={showConfetti}/>
            <Lottie width="120px" height="120px" animationData={success} onComplete={afterLottie} paused={!playLottie}/>
            <FadeWrapper show={playLottie} fadeSpeed="1500ms">
                <TextDiv fontSize="16px" fontWeight="600" marginTop="20px">VERIFIED</TextDiv>
            </FadeWrapper>
        </FlexColumnWrapper>
    )
}
export default SuccessSplash