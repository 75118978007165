import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
    width: 100%;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 56.17021276595745%;
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    ${({marginLeft}) => marginLeft ? `margin-left: ${marginLeft};` : ""}
    ${({marginRight}) => marginRight ? `margin-right: ${marginRight};` : ""}

    &:before, &:after {
        content: " ";
        display: table;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 2;
        grid-row-end: 2;
    }

    &:after {
        clear: both;
    }
`
const StyledIFrame = styled.iframe`
    border:none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
`

const Vimeo = ({id, allowfullscreen, title, ...rest}) => (
    <StyledWrapper {...rest}>
        <StyledIFrame 
            src={`https://player.vimeo.com/video/${id}`}
            frameborder="0"
            allow={`autoplay; ${allowfullscreen ? "fullscreen" : ""}`}
            allowfullscreen={allowfullscreen}
            title={title}
        />
    </StyledWrapper>
)

export default Vimeo