import React from "react"
import styled from "styled-components"
import arrow from "../images/dropdownarrow.svg"

const StyledSelect = styled.select`
    display: ${({display}) => display};
	font-size: 14px;
	font-weight: 400;
    color: ${({theme}) => theme.colors.text};
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
	width: 100%;
	${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
	box-sizing: border-box;
    margin-top: ${({marginTop}) => marginTop || "0px"};
    margin-bottom: ${({marginBottom}) => marginBottom || "0px"};
    border: 1px solid ${({theme}) => theme.colors.inputBorder};
	box-shadow: ${({theme}) => theme.colors.shadow};
	border-radius: 5px;
	appearance: none;
	background-color: ${({theme}) => theme.colors.background};
	background-image: url(${arrow});
	background-repeat: no-repeat, repeat;
	background-position: right 15px top 50%, 0 0;
    background-size: 10px auto, 100%;
    
    &:hover {
        border-color: ${({theme}) => theme.colors.focusInputBorder};
        background-color: ${({theme}) => theme.colors.selectedBackground};
    }

    &:focus {
        outline: none;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 16px;
    }
`

const StyledOption = styled.option`
`

export default ({items, loading, ...rest}) => (
    <StyledSelect disabled={loading} {...rest}>
        {
            items.map(item => (
                <StyledOption key={item.value} value={item.value}>
                    {item.label}
                </StyledOption>
            ))
        }
    </StyledSelect>
)