import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import {useDispatch, useSelector} from "react-redux"

import ModalLayout, { Modal, ModalLogo } from "../../components/modallayout"
import ProgressBar, { ProgressStep, ProgressDivider, PROGRESS_STATUS } from "../../components/progressbar" 
import SEO from "../../components/seo"
import Header from "../../components/header"
import FlowContainer from "../../components/flowcontainer"
import CardRadioButton from "../../components/cardradiobutton"
import Ellipsis from "../../components/ellipsis"
import ErrorMessage from "../../components/errormessage"
import FlexColumnWrapper from "../../components/flexcolumnwrapper"

import { fetchProfile, updateProfile } from "../../appstate/userprofile"
import { useAuth } from "../../services/auth"
import { useTheme } from "../../services/theme"
import { callThunk, isEmptyObject } from "../../services/helper"
import { handleEventError } from "../../services/error"
import * as CONSTANTS from "../../services/constant"

const SelectPersona = () => {
    const [persona, setPersona] = useState()
    const [submitting, setSubmitting] = useState(false)
    const [validationError, setValidationError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(true)

    const userProfile = useSelector(state => state.userProfile)
    const user = useAuth().getUser()
    const theme = useTheme()
    const dispatch = useDispatch()
    
    const handleSubmit = async (e, selectedPersona) => {
        try {
            e.preventDefault()
        
            //save persona
            setPersona(selectedPersona)
            setSubmitting(true)
            await callThunk( dispatch, updateProfile, [ user, { ...userProfile, persona: selectedPersona}])
            if(selectedPersona !== CONSTANTS.USER_PERSONA_NONE && userProfile.intent !==  CONSTANTS.USER_INTENT_EXPLORE) {
                navigate(`/app/websites/create`)
            } else {
                navigate(`/app/setup/overview`)
            }

        } catch (err) {
            setErrorMessage("Oops! Something's not right. Please try again or reach out to customer support.")
            setValidationError(true)
            handleEventError(err, user, false)
        } finally {
            setSubmitting(false)
        }
    }

    useEffect(() => {
        if(!loading) {
            return
        }
        if(!isEmptyObject(userProfile)) {
            setLoading(false)
            return
        }

        if(!user) {
            return
        }

        let unmounted = false
        const loadProfile = async () => {

            try {
                await callThunk(dispatch, fetchProfile, [user])
            } catch (err) {
                handleEventError(err, user, false)
                if(!unmounted) {
                    setErrorMessage("Oops! Something's not right. Please try again or reach out to customer support.")
                    setValidationError(true)
                }
            } finally {
                if(!unmounted) {
                    setLoading(false)
                }
            }
        }
        loadProfile()
        return () => unmounted = true
    },[user, userProfile, dispatch, loading])

    return (
        <ModalLayout 
            background="linear-gradient(45deg, #2858B1, #3BC5FF)"
        >
            <SEO title="Setup Account" />
            <Modal
                minWidth="max(340px, 50%)"
                minHeight="max(580px, 40%)"
            >
                <FlowContainer>
                    <ModalLogo/>
                    <ProgressBar 
                        paddingTop="10px"
                        paddingBottom="20px"

                    >
                        <ProgressStep status={PROGRESS_STATUS.COMPLETE} minWidth="110px">Signup</ProgressStep>
                        <ProgressDivider/>
                        <ProgressStep status={PROGRESS_STATUS.WIP} minWidth="110px">Setup Account</ProgressStep>
                        <ProgressDivider/>
                        <ProgressStep status={PROGRESS_STATUS.TBD} minWidth="110px">Add Website</ProgressStep>
                    </ProgressBar>
                    <Header 
                        title={"Who do you create websites for?"}
                        titleFontSize="28px"
                        paddingTop="0px"
                        paddingBottom="20px"
                    />
                    <FlexColumnWrapper horizontalAlign="center">
                        <CardRadioButton
                            selected={persona === CONSTANTS.USER_PERSONA_CLIENTS} 
                            disabled={(submitting || loading) && persona !== CONSTANTS.USER_PERSONA_CLIENTS}
                            onClick={(loading || submitting) ? null : e => handleSubmit(e, CONSTANTS.USER_PERSONA_CLIENTS)}
                            marginTop = "7.5px"
                            marginBottom = "7.5px"
                            minWidth = "300px"
                            paddingLeft = "25px"
                            paddingRight = "25px"
                        >
                            Clients (as a freelancer or agency)
                        </CardRadioButton>
                        <CardRadioButton
                            selected={persona === CONSTANTS.USER_PERSONA_COMPANY} 
                            disabled={(submitting || loading) && persona !== CONSTANTS.USER_PERSONA_COMPANY}
                            onClick={(loading || submitting) ? null : e => handleSubmit(e, CONSTANTS.USER_PERSONA_COMPANY)}
                            marginTop = "7.5px"
                            marginBottom = "7.5px"
                            minWidth = "300px"
                            paddingLeft = "25px"
                            paddingRight = "25px"

                        >
                            The company I work for
                        </CardRadioButton>
                        <CardRadioButton
                            selected={persona === CONSTANTS.USER_PERSONA_MYSELF} 
                            disabled={(submitting || loading) && persona !== CONSTANTS.USER_PERSONA_MYSELF}
                            onClick={(loading || submitting) ? null : e => handleSubmit(e, CONSTANTS.USER_PERSONA_MYSELF)}
                            marginTop = "7.5px"
                            marginBottom = "7.5px"
                            minWidth = "300px"
                            paddingLeft = "25px"
                            paddingRight = "25px"
                        >
                            My business (or myself)
                        </CardRadioButton>
                        <CardRadioButton
                            selected={persona === CONSTANTS.USER_PERSONA_NONE} 
                            disabled={(submitting || loading) && persona !== CONSTANTS.USER_PERSONA_NONE}
                            onClick={(loading || submitting) ? null : e => handleSubmit(e, CONSTANTS.USER_PERSONA_NONE)}
                            marginTop = "7.5px"
                            marginBottom = "7.5px"
                            minWidth = "300px"
                            paddingLeft = "25px"
                            paddingRight = "25px"
                        >
                            I don't create websites 
                        </CardRadioButton>
                    </FlexColumnWrapper>
                    <ErrorMessage paddingBottom="15px">
                        {validationError? errorMessage : ""}
                        {(submitting || loading)? <Ellipsis color={theme.colors.primary} /> : ""}
                    </ErrorMessage>
                </FlowContainer>
            </Modal>
            
        </ModalLayout>
    )
}
export default SelectPersona