import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import ErrorMessage from "../components/errormessage"
import DropDown from "../components/dropdown"
import ButtonListItem, { ButtonList } from "../components/buttonlistitem"
import SubText from "../components/subtext"
import HelpLink from "../components/helplink"
import LoadState from "../components/loadstate"
import TextSpan from "../components/textspan"
import HubspotButton from "../components/hubspotbutton"

import { addForm } from "../appstate/websites"
import { fetchForms } from "../appstate/hubspotforms"
import { useAuth } from "../services/auth"
import { useTheme } from "../services/theme"
import { handleEventError } from "../services/error"
import * as CONSTANTS from "../services/constant"
import { callThunk } from "../services/helper"


const getPortalId = (accounts, selectedAccount) => {
    const currentAccount = accounts.find(account => account.accountKey === selectedAccount) 
    return currentAccount ? currentAccount.metaData.hubId : '' 
}

const AddHubspotForm = ({currentWebsite, align}) => {

    const [hubspotAccounts, setHubspotAccounts] = useState([])
    const [selectedHubspotAccount, setSelectedHubspotAccount] = useState('')
    const [selectedHubspotForm, setSelectedHubspotForm] = useState(null)
    const [validationError, setValidationError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loadingHubspotForms, setLoadingHubspotForms] = useState(false)

    const auth = useAuth()
    const user = auth.getUser()
    const theme = useTheme()
    const permissions = useSelector(state => state.permissions)    

    const websiteId = currentWebsite ? currentWebsite.submissionId : ''
    const hubspotForms = useSelector(state => state.hubspotForms[selectedHubspotAccount]) || []
    const portalId = getPortalId(hubspotAccounts, selectedHubspotAccount)
    const dispatch = useDispatch()


    const addHubspotForm = async (e, {formId, name}) => {
        try {
            e.preventDefault()
            //check if form was already added to website 
            if(addedPreviously(formId)) {
                setErrorMessage(`${name} has already been added to this website!`)
                setValidationError(true)
                return
            }

            //add form
            setSelectedHubspotForm({formId, name})
            const formKey = `${CONSTANTS.HUBSPOT_FORM_PROVIDER_KEY}-${portalId}-${formId}`
            await callThunk(dispatch, addForm, [user, websiteId, {
                provider: CONSTANTS.HUBSPOT_FORM_PROVIDER_KEY,
                portalId: portalId,
                formId,
                name,
                formKey, 
                installStatus: false
            }])
            navigate(`/app/websites/${websiteId}/forms/${formKey}/addattribute`)

        } catch (err) {
            setErrorMessage("Oops! We couldn't add that form. Please try again or reach out to customer support.")
            setValidationError(true)
            handleEventError(err, user, false)
        } finally {
            setSelectedHubspotForm(null)
        }
    }

    const addedPreviously = formId => {
        if(!currentWebsite.submissionData.forms) {
            return false
        }
        return currentWebsite.submissionData.forms[`${CONSTANTS.HUBSPOT_FORM_PROVIDER_KEY}-${portalId}-${formId}`] ? true : false   
    }

    //update hubspot accounts when permissions change
    useEffect(() => {
        if(!permissions || !permissions.oAuthAccounts) {
            return
        }

        const hubspotOAuthAccounts = Object.values(permissions.oAuthAccounts)
            .filter(account => account.oAuthProviderKey === CONSTANTS.HUBSPOT_OAUTH_PROVIDER_KEY)
        if(!hubspotOAuthAccounts || hubspotOAuthAccounts.length === 0){
            return
        }
        setHubspotAccounts(hubspotOAuthAccounts)
        setSelectedHubspotAccount(hubspotOAuthAccounts[0].accountKey)
        
    }, [permissions])

    //update hubspot forms when account is changed
    useEffect(() => {
        if(!user || !selectedHubspotAccount || selectedHubspotAccount === '' || !hubspotAccounts || hubspotAccounts.length === 0) {
            return
        }
        let unmounted = false
        const loadForms = async () => {
            try {
                setLoadingHubspotForms(true)
                const hubspotAccount = hubspotAccounts.find(account => account.accountKey === selectedHubspotAccount)
                await callThunk(dispatch, fetchForms, [user, hubspotAccount, true])
            } catch (err) {
                handleEventError(err, user, false)
                if(!unmounted) {
                    setErrorMessage("Oops! We couldn't load your forms from Hubspot. Try refreshing the page or reach out to customer support.")
                    setValidationError(true)    
                }
            } finally {
                if(!unmounted) {
                    setLoadingHubspotForms(false)
                }
            }
        }
        loadForms()
        return () => unmounted = true
        
    }, [user, selectedHubspotAccount, hubspotAccounts, dispatch])

    if(!currentWebsite) {
        return (
            <LoadState 
                height="190px" 
                width="60px"
                spinnerSize="40px"
                show={true}
            />
        ) 
    }

    return (
        <>
                <DropDown 
                    display={hubspotAccounts.length !== 0 ? "block": "none"}
                    items={hubspotAccounts.map(item => {return {value: item.accountKey, label: item.metaData.domain}})}
                    value={selectedHubspotAccount}
                    onChange={e=>setSelectedHubspotAccount(e.target.value)}
                    maxWidth="540px"
                    marginBottom="20px"
                />

                <ButtonList 
                    display={hubspotAccounts.length !== 0 ? "block": "none"}
                    maxWidth="540px"
                >
                    {hubspotForms.map(({ formId, name }) =>
                        <ButtonListItem 
                            key={ formId } 
                            onClick={e => addHubspotForm(e, { formId, name })} 
                            callToAction={addedPreviously(formId)?  "Form Added" : "Add Form →"}
                            wait={selectedHubspotForm && selectedHubspotForm.formId === formId}
                            disabled={(selectedHubspotForm || addedPreviously(formId))? true : false}
                            maxWidth="540px"
                        >
                            {name}
                        </ButtonListItem>
                    )}
                    <LoadState 
                        height={(hubspotForms && hubspotForms.length !== 0) ? "30px" : "200px"} 
                        spinnerSize={(hubspotForms && hubspotForms.length !== 0) ? "15px" : "40px"} 
                        show={loadingHubspotForms}
                    />
                </ButtonList>  
                <HubspotButton
                    show={hubspotAccounts.length === 0}
                    redirectPath={`/app/websites/${websiteId}/forms/create`}
                    setValidationError={setValidationError}
                    setErrorMessage={setErrorMessage}
                />
                <SubText 
                    maxWidth="540px" 
                    marginBottom="10px" 
                    hide={loadingHubspotForms} 
                    align={align} 
                    breakpoint={theme.breakpoints.mobile} 
                    responsiveAlign="center"
                >
                    {hubspotAccounts.length !== 0 ? (
                        <>
                            <TextSpan>Don't see the form you need?&nbsp;</TextSpan>
                            <HelpLink 
                                to={`/app/integrations/create`} 
                                state={{redirectPath: `/app/websites/${websiteId}/forms/create`}}
                                display="inline" 
                                align="left" 
                                fontWeight="600">
                                Connect to a different Hubspot account
                            </HelpLink> 
                            <TextSpan>&nbsp;or&nbsp;</TextSpan>
                            <HelpLink to={`/app/websites/${websiteId}/forms/createmanual`} display="inline" align="left" fontWeight="600">
                                add the form manually
                            </HelpLink>
                            <TextSpan>&nbsp;instead.</TextSpan>
                        </>
                    ) : (
                        <>
                            <TextSpan>Can't connect to Hubspot?&nbsp;</TextSpan>
                            <HelpLink to={`/app/websites/${websiteId}/forms/createmanual`} display="inline" align="left" fontWeight="600">
                                Add the form manually
                            </HelpLink>
                            <TextSpan>&nbsp;instead.</TextSpan>
                        </>
                    )} 
                </SubText>
                <ErrorMessage paddingBottom="15px">
                    &nbsp;{validationError? errorMessage : ""}&nbsp;
                </ErrorMessage>
        </>
    )
}
export default AddHubspotForm