import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
 
const StyledDiv = styled.div`
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.background};
    box-shadow: ${({theme}) => theme.shadow};
    transition: transform 400ms cubic-bezier(.455, .03, .515, .955), box-shadow 200ms cubic-bezier(.455, .03, .515, .955);
    ${({minHeight}) => `min-height: ${minHeight};`}

    &:hover {
        transform: translate(0px, -2px);
        box-shadow: ${({theme}) => theme.hoverShadow};
    }
`
const CardImage = styled.div`
    height: 207px;
    background-image: url('${({src}) => src}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;   
    
    @media (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        height: 215px;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        height: 267px;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        height: 209px;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.small}) {
        height: 178px;
    }

`
const StyledLink = styled(Link)`
    text-decoration: none;
    min-width: 340px;
`
const Category = styled.div`
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
    color: ${({theme}) => theme.colors.extraSubtleText};
    text-transform: uppercase;
    font-size: 12px;
    transition: color 200ms cubic-bezier(.455, .03, .515, .955);

    ${StyledDiv}:hover & {
        color: ${({theme}) => theme.colors.secondary};
    }
`

const Title = styled.h3`
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${({theme}) => theme.colors.text};
    font-size: 16px;
`

const CallToAction = styled.div`
    padding: 15px;
    color: ${({theme}) => theme.colors.primary};
    font-weight: 600;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    transition: color 200ms cubic-bezier(.455, .03, .515, .955), background-color 200ms cubic-bezier(.455, .03, .515, .955);

    ${StyledDiv}:hover & {
        background-color: ${({theme}) => theme.colors.primary};
        color: ${({theme}) => theme.colors.inverseText};
    }
`

const Content = styled.div`
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    color: ${({theme}) => theme.colors.text};
`

export const CardPanel = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    grid-gap: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${({marginTop}) => marginTop || "0px"};
    margin-bottom: ${({marginBottom}) => marginBottom || "0px"};
    max-width: 1449px;

    @media (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        max-width: 1050px;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        max-width: 510px;
    }
`

export default ({to, src, category, title, children, callToAction, ...rest}) => {

    return (
        <StyledLink to={to}>
            <StyledDiv {...rest}>
                {src && <CardImage src={src}/>}
                <Category>{category}</Category>
                <Title>{title}</Title>
                {children && <Content>{children}</Content>}                
                <CallToAction>{callToAction}</CallToAction>
            </StyledDiv>
        </StyledLink>
    )
}