import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import SEO from "../components/seo"
import LoadState from "../components/loadstate"
import Header from "../components/header"
import SiteLayout from "../components/sitelayout"
import MenuItem from "../components/menuitem"
import ErrorState from "../components/errorstate"

import { useAuth } from "../services/auth" 
import { useTheme } from "../services/theme"
import { handleEventError } from "../services/error"

const Logout = ({location}) => {
    const auth = useAuth()
    const theme = useTheme()

    const path = (location.state && location.state.redirectPath) ? location.state.redirectPath : `/`
    const [error, setError] = useState(false)

    useEffect(() => {
        if(auth.isLoggedIn()){
            let unmounted = false
            const logout = async () => {
                try {
                    await auth.signout()
                } catch (err) {
                    handleEventError(err, false, false)
                    if(!unmounted) {
                        setError(true)
                    }
                }

            }
            logout()
            return () => unmounted = true
        } else {
            navigate(path, { replace: true})
        }
    },[auth, path])

    return (
        <SiteLayout 
            hideLogin={true} 
            hideUserProfile={true} 
            hideMyWebsites={true}
            menu={
                <MenuItem to="/help" marginLeft="5px" marginRight="5px">Help</MenuItem>
            }
        >
            <SEO title="Sign Out" />
            {
                !error && 
                <Header 
                    title="Signing out..."
                    subTitle="Please wait. You'll be redirected automatically."
                    minHeight="30vh"
                    paddingTop="80px"
                    paddingBottom="80px"
                /> 
            }
            <LoadState
                spinnerSize="40px"
                height="200px"  
                show={!error}
            />
            <ErrorState
                show={error}
                paddingTop="10%" 
                breakpoint={theme.breakpoints.tablet} 
                responsiveStyle="padding-left: 10%; padding-right: 10%;"
                title="Oops! Couldn't sign out."
            >
                Something's not right. Please try again or reach out to customer support.
            </ErrorState>
        </SiteLayout>
    )
}

export default Logout