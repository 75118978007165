import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import AppLayout from "../../components/applayout"
import SEO from "../../components/seo"
import WebsiteNav from "../../components/websitenav"
import { LeftNavSection } from "../../components/leftnav"
import Segment, {SegmentSubTitle, SegmentTitle} from "../../components/segment"
import ErrorState from "../../components/errorstate"

import AddHubspotForm from "../../appfeatures/addhubspotform"
import { fetchWebsites } from "../../appstate/websites"
import { useAuth } from "../../services/auth"
import { useTheme } from "../../services/theme"
import { handleEventError } from "../../services/error"
import { callThunk } from "../../services/helper"


const AddForm = ({websiteId}) => {

    //const [formProvider, setFormProvider] = useState(CONSTANTS.FORM_PROVIDER_HUBSPOT)
    const currentWebsite = useSelector(state => state.websites.find(website => website.submissionId === websiteId))
    const dispatch = useDispatch()
    const auth = useAuth()
    const user = auth.getUser() 
    const theme = useTheme()

    const [loadError, setLoadError] = useState(false)

    useEffect(() => {
        if(!user || currentWebsite){
            return 
        }

        //refresh websites if state is stale
        let unmounted = false
        const refreshWebsites = async () => {
            try {
                await callThunk(dispatch, fetchWebsites, [user])
            } catch (err) {
                handleEventError(err, user, false)
                if(!unmounted) {
                    setLoadError(true)
                }
            }
        } 
        refreshWebsites()
        return () => unmounted = true

    },[user, currentWebsite, dispatch])
    
    if(!websiteId) {
        navigate("/app/websites")
        return null
    }
    const domain = currentWebsite ? currentWebsite.submissionData.domain.slice(8) : ''
    const stage = currentWebsite ? currentWebsite.submissionData.stage : ''

    return (
        <AppLayout
            contextTitle={domain}
            contextCategory={stage}
            ctaPath={`/help/guides/hubspot-webflow-form-tool#setupform`}
            ctaContent="See Guide"
        >
            <SEO title="Add Form" />
            {
                !loadError &&
                <>
                    <WebsiteNav websiteId={websiteId}/>
                    <LeftNavSection first="true">
                        <Segment header={<SegmentTitle>Let's add a form</SegmentTitle>}>
                            <SegmentSubTitle marginBottom="15px">Connect to your Hubspot account and choose a form.</SegmentSubTitle>
                            <AddHubspotForm currentWebsite={currentWebsite} align="left"/>
                        </Segment>
                    </LeftNavSection>
                </>
            }
            <ErrorState
                show={loadError}
                paddingTop="10%" 
                breakpoint={theme.breakpoints.tablet} 
                responsiveStyle="padding-left: 10%; padding-right: 10%;"
                title="Oops! Couldn't load your website."
            >
                Something's not right. Try refreshing the page or reach out to customer support.
            </ErrorState>
            
        </AppLayout>
    )
}
export default AddForm