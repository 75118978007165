import React from "react"
import styled from "styled-components"

const StyledLink = styled.a`
    display: ${props => props.display ? props.display : "block"};
    margin-top: 15px;
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
    text-align: ${props => props.align ? props.align : "center"};
    font-weight: ${props => props.fontWeight ? props.fontWeight : "400"}
`

const ExternalLink = ({to, children, ...rest}) => (
    <StyledLink {...rest} href={to} target="_blank" rel="noopener noreferrer">{children}</StyledLink>
)
export default ExternalLink