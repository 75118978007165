import React, { useState } from "react"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"

import ErrorMessage from "../components/errormessage"
import Button, { ButtonWrapper } from "../components/button"
import SubText from "../components/subtext"
import TextArea from "../components/textarea"
import Ellipsis from "../components/ellipsis"
import LoadState from "../components/loadstate"
import ExternalLink from "../components/externallink"
import TextInput from "../components/textinput"

import { addForm } from "../appstate/websites"
import { useAuth } from "../services/auth"
import { useTheme } from "../services/theme"
import { handleEventError } from "../services/error"
import { setInputState, getFromString, callThunk, isEmpty } from "../services/helper"
import * as CONSTANTS from "../services/constant"

const parseEmbedCode = (code) => {
    return {
        portalId: getFromString(code,`portalId: "`,`"`),
        formId: getFromString(code,`formId: "`,`"`) 
    }
}

const AddHubspotFormWithCode = ({currentWebsite, align, children}) => {
    const [hubspotEmbedCode, setHubspotEmbedCode] = useState()
    const [validationError, setValidationError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [formName, setFormName] = useState('')


    const auth = useAuth()
    const user = auth.getUser()
    const theme = useTheme()
    const dispatch = useDispatch()

    const handleSubmit = async (e) => {
        try {

            const websiteId = currentWebsite.submissionId

            e.preventDefault()

            if(isEmpty(formName)){
                setErrorMessage("Oops! A form name is required.")
                setValidationError(true)
                return    
            }

            if(!hubspotEmbedCode){
                setErrorMessage("Oops! The form embed code is empty.")
                setValidationError(true)
                return    
            }

            const { portalId, formId } = parseEmbedCode(hubspotEmbedCode)
            if(!portalId || portalId.length === 0 || !formId || formId.length === 0) {
                setErrorMessage("Oops! The form embed code is invalid.")
                setValidationError(true)
                return    
            }
            //check if form has already been added to website 
            const formKey = `${CONSTANTS.HUBSPOT_FORM_PROVIDER_KEY}-${portalId}-${formId}`
            if(currentWebsite.submissionData && currentWebsite.submissionData.forms && currentWebsite.submissionData.forms[formKey]) {
                setErrorMessage("Oops! This form has already been added to the website.")
                setValidationError(true)
                return    
            }

            //add form
            setSubmitting(true)

            await callThunk(dispatch, addForm, [user, websiteId, {
                provider: CONSTANTS.HUBSPOT_FORM_PROVIDER_KEY,
                portalId,
                formId,
                name: formName,//`Hubspot form - ${(new Date()).toLocaleDateString()}`,
                formKey, 
                installStatus: false
            }])
            navigate(`/app/websites/${websiteId}/forms/${formKey}/addattribute`)

        } catch (err) {
            setErrorMessage("Oops! Something's not right. We couldn't add that form. Please try again or reach out to customer support.")
            setValidationError(true)
            handleEventError(err, user, false)
        } finally {
            setSubmitting(false)
        }
        
    }

    if(!currentWebsite) {
        return (
            <LoadState 
                height="230px" 
                width="60px"
                spinnerSize="40px"
                show={true}
            />
        ) 
    }

    return (
        <>
            <TextArea 
                placeholder="Copy and paste the form's emebd code from Hubspot" 
                label="Hubspot Form Embed Code"
                type="text" maxWidth="540px" 
                marginTop="10px"
                marginBottom="10px"
                value={hubspotEmbedCode} 
                onChange={e => setInputState(e.target.value, setHubspotEmbedCode, false, setValidationError)}
                returnHandler={handleSubmit}
                error={validationError ? true : false}
                focus={true}
                fontColor={theme.colors.subtleText}
            />
            <SubText maxWidth="540px" align={align} breakpoint={theme.breakpoints.mobile} responsiveAlign="center">
                Not sure how to get the form's embed code from Hubspot? See 
                <ExternalLink 
                    to="https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#add-the-form-embed-code" 
                    display="inline" align="left" fontWeight="600"
                >
                &nbsp;Hubspot's documentation.
                </ExternalLink>
            </SubText>

            <TextInput 
                placeholder="What's this form for?"
                label="Form Name"
                type="text" maxWidth="540px" 
                marginLeft="0px"
                marginRight="0px"
                marginTop="20px"
                marginBottom="10px"
                value={formName} 
                onChange={e => setInputState(e.target.value, setFormName, false, setValidationError)}
                error={validationError ? true : false}
                returnHandler={handleSubmit}
            />
            
            <ErrorMessage 
                maxWidth="540px" 
                align={align}
                breakpoint={theme.breakpoints.mobile}
                responsiveAlign="center"
            >
                {validationError? errorMessage : ""}
            </ErrorMessage>
            <ButtonWrapper align={align} maxWidth="540px">
                {
                    children
                }
                <Button type="button" marginLeft="5px" marginRight="5px" disabled={submitting} onClick={handleSubmit}>
                    {!submitting ? `Add Form` : <Ellipsis/>}
                </Button>
            </ButtonWrapper>
        </>
    )
}
export default AddHubspotFormWithCode