import {navigate} from "gatsby"
import { createOAuthToken } from "../appstate/permissions"
import * as CONSTANTS from "./constant"
import { isMobile, encodeBase64Uri, removeLocalStorageItem, callThunk } from "./helper"
import { handleEventError } from "./error"

export const initiateOAuth = (user, dispatch, config) => {
    const popupMode = !isMobile()
    const state = encodeBase64Uri(JSON.stringify({
        redirectPath: config.redirectPath,
        codeKey: config.codeKey,
        errorCodeKey: config.errorCodeKey,
        errorMessageKey: config.errorMessageKey,
        oAuthProviderKey: config.oAuthProviderKey,
        oAuthMode: popupMode ? CONSTANTS.OAUTH_MODE_POPUP : CONSTANTS.OAUTH_MODE_REDIRECT
    }))

    const url = config.oAuthUrl+`&state=${state}`

    if(popupMode){
        //open popup
        const width = 540, height = 840
        const left = window.screenX + (window.outerWidth - width) / 2
        const top = window.screenY + (window.outerHeight - height) / 2.5
        const windowFeatures = `toolbar=0,scrollbars=1,status=1,resizable=0,location=1,menuBar=0,`+
            `width=${width},height=${height},top=${top},left=${left}`
        const popup = window.open(url, `Connecting...`, windowFeatures)

        let isListening = true
        //set listener
        const storageListener = async (e) => {
            try {
                if(e.key === CONSTANTS.OAUTH_RESULT_KEY) {
                    if(!e.newValue) {
                        return
                    }
                    const oAuthResult = JSON.parse(e.newValue)
                    if(oAuthResult && oAuthResult.code){
                        await callThunk(dispatch, createOAuthToken, [user, config.oAuthProviderKey, oAuthResult.code, CONSTANTS.OAUTH_TOKEN_TYPE_ACCESS])
                        navigate(config.redirectPath)
                    } else {
                        config.setErrorMessage(oAuthResult.errorMessage)
                        config.setValidationError(true)
                    }
                    if(isListening) {
                        window.removeEventListener('storage', storageListener)
                        isListening = false
                    }
                    popup.close()
                    removeLocalStorageItem(CONSTANTS.OAUTH_RESULT_KEY)
                }
            } catch (err) {
                handleEventError(err, false, false)
            }
        }
        //remove listener if window is closed before completing oauth
        const closeTimer = setInterval(() => {
            if(popup.closed) {
                clearInterval(closeTimer)
                if(isListening) {
                    window.removeEventListener('storage', storageListener)
                    isListening = false
                }
            }
        }, 1000)
        window.addEventListener('storage',storageListener)

    } else {
        window.location.href = url;
    }
}