import React, {useEffect, useState} from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import AppLayout from "../../components/applayout"
import SEO from "../../components/seo"
import Section from "../../components/section"
import Segment, { SegmentTitle} from "../../components/segment"
import Button, { ButtonWrapper } from "../../components/button"
import ReadOnlyField from "../../components/readonlyfield"
import Ellipsis from "../../components/ellipsis"
import ErrorMessage from "../../components/errormessage"

import { useAuth } from "../../services/auth"
import { useTheme } from "../../services/theme"
import { deleteOAuthToken } from "../../appstate/permissions"
import { handleEventError } from "../../services/error"
import { parseQueryString, callThunk } from "../../services/helper"
import * as CONSTANTS from "../../services/constant"

const IntegrationSettings = ({integrationId, location}) => {

    const provider = parseQueryString(CONSTANTS.INTEGRATION_PROVIDER_KEY, location.search)
    const user = useAuth().getUser()
    const dispatch = useDispatch()
    const permissions = useSelector(state => state.permissions)
    const theme = useTheme()
    const [submitting, setSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [validationError, setValidationError] = useState(false)
    const [integration, setIntegration] = useState({})

    const handleDisconnect = async (e) => {
        try {
            e.preventDefault()

            //delete integration
            setSubmitting(true)
            await callThunk(dispatch, deleteOAuthToken,[user, integration.oAuthProviderKey, integration.accountKey, integration.tokenSet.refreshToken])
            navigate(`/app/integrations`,{ replace: true})

        } catch (err) {
            setErrorMessage("Oops! Something's not right. Please try again or reach out to customer support.")
            setValidationError(true)
            handleEventError(err, user, false)
        } finally {
            setSubmitting(false)
        }

    }

    //load oAuth account details
    useEffect(() => {

        if(!permissions || !permissions.oAuthAccounts) {
            return
        }

        const account = Object.values(permissions.oAuthAccounts)
            .find(account => (account.accountKey === integrationId && account.oAuthProviderKey === provider))
        
        if(account) {
            setIntegration(account)
        } else {
            navigate(`/app/integrations`,{ replace: true})
        }
    }, [permissions, integrationId, provider])

    return (
        <AppLayout
            ctaPath="/help/integrations/hubspot-disconnect"
            ctaContent="See FAQs"
            contextTitle={ integration.metaData && integration.metaData.domain ? integration.metaData.domain : ""}
        >
            <SEO title="Integration Details" />
            <Section paddingBottom="60px" paddingTop="60px">
                <Segment
                    header={<SegmentTitle>Integration Details</SegmentTitle>}
                >
                    <ReadOnlyField fieldName="Connected Account Type" paddingBottom="20px" labelColor={theme.colors.extraSubtleText}>
                        Hubspot
                    </ReadOnlyField>

                    <ReadOnlyField fieldName="Hubspot Portal" paddingBottom="20px" labelColor={theme.colors.extraSubtleText}>
                        { integration.metaData && integration.metaData.domain ? integration.metaData.domain : ""}
                    </ReadOnlyField>
                    <ReadOnlyField fieldName="Hubspot User" paddingBottom="20px" labelColor={theme.colors.extraSubtleText}>
                        { integration.metaData && integration.metaData.user ? integration.metaData.user : ""}
                    </ReadOnlyField>
                    <ErrorMessage paddingBottom="15px">
                        {validationError? errorMessage : ""}
                    </ErrorMessage>
                    <ButtonWrapper align="left" maxWidth="540px">
                        <Button type="button" disabled={submitting} onClick={handleDisconnect}>
                        {!submitting ? `Disconnect` : <Ellipsis/>}
                        </Button>
                    </ButtonWrapper>                
                </Segment>
            </Section>
        </AppLayout>
    )
}
export default IntegrationSettings