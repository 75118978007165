
import { navigate } from "gatsby"
import { isBrowser } from "../../services/helper"

const WebsiteDashboard = ({websiteId}) => {
    if(isBrowser()) {
        navigate(`/app/websites/${websiteId}/forms`, { replace: true})    
    }
    return null
}
export default WebsiteDashboard