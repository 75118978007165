import React, {useState, useEffect} from "react"
import {Link, navigate} from "gatsby"

import AppLayout from "../components/applayout"
import SEO from "../components/seo"
import Button, { ButtonWrapper } from "../components/button"
import TextArea from "../components/textarea"
import Ellipsis from "../components/ellipsis"
import FlowContainer from "../components/flowcontainer"
import Header from "../components/header"
import TextDiv from "../components/textdiv"
import ErrorMessage from "../components/errormessage"

import { callApi } from "../services/api"
import { useAuth } from "../services/auth"
import { handleEventError } from "../services/error"

const DeleteAccount = () => {

    const user = useAuth().getUser()
    const [feedback, setFeedback] = useState('')
    const [deleting, setDeleting] = useState(false)
    const [deleteScheduled, setDeleteScheduled] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [validationError, setValidationError] = useState(false)

    const handleDelete = async (e) => {
        try {
            e.preventDefault()

            //delete account
            setDeleting(true)
            await callApi( user, {
                method: 'post',
                url:'/records',
                data: {
                    collectionId: process.env.GATSBY_DELETED_USERS_COLLECTION_ID,
                    submissionData: {
                        uid: user.uid,
                        name: user.displayName, 
                        email: user.email,
                        photoUrl: user.photoURL,
                        creationTime: user.metadata.creationTime,
                        feedback: feedback 
                    }
                }
            })
            setDeleteScheduled(true)

        } catch (err) {
            setErrorMessage("Oops! Something's not right. Please try again or reach out to customer support.")
            setValidationError(true)
            handleEventError(err, user, false)
        } finally {
            setDeleting(false)
        }

    }

    useEffect(() => {
        if(deleteScheduled) {
            const timer = setTimeout(() => {
                navigate(`/app/logout`)
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [deleteScheduled])

    return (
        <AppLayout
            ctaPath="/help/account/delete"
            ctaContent="See FAQs"
            contextTitle={(user.displayName ? (user.displayName.split(" ")[0] + "'s account"): "My account")}
        >
            <SEO title="Delete Account" />
            <FlowContainer>
                {
                    !deleteScheduled && 
                    <>
                        <Header 
                            title={user.displayName.split(" ")[0]+", we're sorry to see you go."}
                            subTitle="We value your feedback. Please let us know why you are leaving and how we could have served you better."
                            paddingTop="40px"
                            paddingBottom="40px"
                            maxWidth="600px"
                        />
                        <TextArea 
                            placeholder="I'm deleting my account because... " 
                            label="Your Feedback"
                            type="text" maxWidth="540px" 
                            marginBottom="15px"
                            value={feedback} 
                            onChange={e => setFeedback(e.target.value)}
                            returnHandler={handleDelete}
                        />
                        <ErrorMessage minHeight="30px" paddingBottom="15px">
                            {validationError? errorMessage : ""}
                        </ErrorMessage>
                        <ButtonWrapper>
                            <Link to="/app/account">
                                <Button type="button" secondary="true" marginLeft="5px" marginRight="5px">Cancel</Button>
                            </Link>  
                            <Button type="button" marginLeft="5px" marginRight="5px" disabled={deleting} onClick={handleDelete}>
                                {!deleting ? `Delete Account` : <Ellipsis/>}
                            </Button>
                        </ButtonWrapper>
                    </>
                }
                {
                    deleteScheduled && 
                    <TextDiv maxWidth="540px">
                        Your account has been scheduled for deletion. You will be signed out automatically. 
                        Please reach out to customer support if you need any other assistance.
                    </TextDiv>    
                }
            </FlowContainer>
        </AppLayout>
    )
}
export default DeleteAccount