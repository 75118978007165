import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, navigate } from "gatsby"

import VerifyWebsite from "../../appfeatures/verifywebsite"
import Header from "../../components/header"
import AppLayout from "../../components/applayout"
import FlowContainer from "../../components/flowcontainer"
import SEO from "../../components/seo"
import Button from "../../components/button"
import ErrorState from "../../components/errorstate"
import Wrapper from "../../components/wrapper"
import FloatSegment from "../../components/floatsegment"

import blueWave from "../../images/bluewave.svg"

import { fetchWebsites } from "../../appstate/websites"
import { useAuth } from "../../services/auth"
import { useTheme } from "../../services/theme"
import { handleEventError } from "../../services/error"
import { callThunk } from "../../services/helper"

const InstallCode = ({websiteId}) => {

    const currentWebsite = useSelector(state => state.websites.find(website => website.submissionId === websiteId))
    const dispatch = useDispatch()
    const auth = useAuth()
    const user = auth.getUser()
    const theme = useTheme() 
    const [loadError, setLoadError] = useState(false)

    useEffect(() => {
        if(!user || currentWebsite){
            return 
        }

        //refresh websites if state is stale
        let unmounted = false
        const refreshWebsites = async () => {
            try {
                await callThunk(dispatch, fetchWebsites, [user])
            } catch (err) {
                handleEventError(err, user, false)
                if(!unmounted) {
                    setLoadError(true)
                }
            }
        } 
        refreshWebsites()
        return () => unmounted = true
    },[user, currentWebsite, dispatch])

    if(!websiteId) {
        navigate("/app/websites")
        return null
    }

    return (
        <AppLayout>
            <SEO title="Add Vimkit" />
            <Wrapper
                backgroundImage={`url(${blueWave})`}
                backgroundSize={`100% 60%`}
                backgroundPosition={`center bottom`}
                backgroundRepeat={`no-repeat`}            
                minHeight="calc(100vh - 65px)"
                breakpoint={theme.breakpoints.mobile}
                responsiveStyle={`background: none;`}
            >
                <FlowContainer minHeight="calc(100vh - 65px)">
                    <FloatSegment 
                        marginLeft="auto" 
                        marginRight="auto" 
                        paddingTop="3%" 
                        paddingBottom="3%"  
                        shadow={true}
                    >
                        <Header 
                            title="Next, add the code snippet."
                            subTitle={`Add the Vimkit code snippet to your website's head code and verify.`}
                            paddingTop="0px"
                            paddingBottom="40px"
                        />
                        {
                            !loadError &&
                            <VerifyWebsite
                                currentWebsite={currentWebsite}
                                successRedirectPath={`/app/websites/${websiteId}/forms/create`}
                                align="center"
                            >
                                <Link to={`/app/websites/${websiteId}/forms/create`}>
                                    <Button type="button" secondary="true" marginRight="10px">Skip</Button>
                                </Link>
                            </VerifyWebsite>
                        }
                        <ErrorState
                            show={loadError}
                            paddingTop="10%" 
                            breakpoint={theme.breakpoints.tablet} 
                            responsiveStyle="padding-left: 10%; padding-right: 10%;"
                            title="Oops! Couldn't load the code snippet."
                        >
                            Something's not right. Try refreshing the page or reach out to customer support.
                        </ErrorState>
                    </FloatSegment>
                </FlowContainer>

            </Wrapper>
            
        </AppLayout>
    )
}
export default InstallCode