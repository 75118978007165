import React from "react"
import styled from "styled-components"
import Container from "./container"

const StyledWrapper = styled.div`
    position: fixed; 
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 19;
    padding-bottom:12.5px;
    padding-top: 12.5px;
    box-shadow: ${({theme}) => theme.shadow};
    background-color: ${({theme}) => theme.colors.background};
`
const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const BottomNav = ({children}) => (
    <StyledWrapper>
        <Container>
            <FlexWrapper>
                {children}
            </FlexWrapper>
        </Container>
    </StyledWrapper>
)

export default BottomNav