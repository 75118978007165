import React from "react"
import { Link } from "gatsby"

import ModalLayout, { Modal, ModalLogo } from "../../components/modallayout"
import ProgressBar, { ProgressStep, ProgressDivider, PROGRESS_STATUS } from "../../components/progressbar" 
import Vimeo from "../../components/vimeo"
import SEO from "../../components/seo"
import FlowContainer from "../../components/flowcontainer"
import Button, { ButtonWrapper } from "../../components/button"
import ExternalLink from "../../components/externallink"

const SeeOverview = () => (
    <ModalLayout 
        background="linear-gradient(45deg, #2858B1, #3BC5FF)"
    >
        <SEO title="Watch Overview" />
        <Modal
            minWidth="max(340px, 50%)"
            minHeight="max(580px, 40%)"
        >
            <FlowContainer>
                <ModalLogo/>
                <ProgressBar 
                    paddingTop="20px"
                    paddingBottom="30px"
                >
                    <ProgressStep status={PROGRESS_STATUS.COMPLETE} minWidth="110px">Signup</ProgressStep>
                    <ProgressDivider/>
                    <ProgressStep status={PROGRESS_STATUS.COMPLETE} minWidth="110px">Setup</ProgressStep>
                    <ProgressDivider/>
                    <ProgressStep status={PROGRESS_STATUS.TBD} minWidth="110px">Add Website</ProgressStep>
                </ProgressBar>
            </FlowContainer>
            <Vimeo id="441030489" allowfullscreen={true} title="Vimkit Overview"/>
            <FlowContainer>
                <ButtonWrapper paddingTop="20px" paddingBottom="20px">
                    <Link to="/app/websites/create"><Button>Add Website</Button></Link>
                    <ExternalLink 
                        to="mailto:?subject=Try%20Vimkit%3F&body=Vimkit's%20free%20tool%20connects%20Webflow%20forms%20with%20Hubspot.%20Check%20it%20out%20-%20https%3A%2F%vimkit.com%2Ftools%2Fhubspot-webflow-form-tool">
                        Share with a teammate
                    </ExternalLink>
                </ButtonWrapper>
                
            </FlowContainer>

        </Modal>
        
    </ModalLayout>
)

export default SeeOverview