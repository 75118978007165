import React from "react"
import styled from "styled-components"

export const PROGRESS_STATUS = {
    COMPLETE: 2,
    WIP: 1,
    TBD: 0
}

const getStatusColor = (theme, status) => {
    switch(status) {
        case PROGRESS_STATUS.WIP: 
            return theme.colors.accent;
        case PROGRESS_STATUS.COMPLETE: 
            return theme.colors.success;
        default: 
            return theme.colors.disabledBackground;
    }
}

const BarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${({paddingTop}) => paddingTop ? `padding-top: ${paddingTop};` : ""}
    ${({paddingBottom}) => paddingBottom ? `padding-bottom: ${paddingBottom};` : ""}

    ${({background}) => background ? `background: ${background};` : ""}
    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {${responsiveStyle}}` : ""}
`

const StepWrapper = styled.div`
    ${({minWidth}) => minWidth ? `min-width: ${minWidth};` : ""}
`

const CompletedCheck = styled.span`
    color: ${({theme}) => theme.colors.success};
`

const StepTitle = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;

`

const StepBar = styled.div`
    height: 5px;
    width: 100%;
    background-color: ${({theme, status}) => getStatusColor(theme, status)};
`

export const ProgressStep = ({status, children, ...rest}) => (
    <StepWrapper {...rest}>
        <StepTitle>
            {status === PROGRESS_STATUS.COMPLETE && <CompletedCheck>&#10004;&nbsp;</CompletedCheck>}
            {children}
        </StepTitle>
        <StepBar status={status}/>
    </StepWrapper>
)

export const ProgressDivider = styled.div`
    width: 2px;
    background-color: ${({theme, backgroundColor}) => backgroundColor || theme.colors.background};
`

const ProgressBar = ({children, ...rest}) => (
    <BarWrapper {...rest}>
        {children}
    </BarWrapper>
)
  
export default ProgressBar