import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
    ${({paddingTop}) => paddingTop ? `padding-top: ${paddingTop};` : ""}
    ${({paddingBottom}) => paddingBottom ? `padding-bottom: ${paddingBottom};` : ""}
`

const StyledLabel = styled.div`
    font-size: 12px;
    font-weight: 600;
    ${({color}) => color ? `color: ${color};` : ""}

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        text-align: center;
    }
`

const StyledValue = styled.div`
`

export default ({fieldName, children, labelColor, ...rest}) => (
    <StyledWrapper {...rest}>
        <StyledLabel color={labelColor}>{fieldName}</StyledLabel>
        <StyledValue>{children}</StyledValue>
    </StyledWrapper>
)