import React from "react"
import { Link } from "gatsby"
import { useSelector } from "react-redux"

import AppLayout from "../../components/applayout"
import SEO from "../../components/seo"
import Card, { CardPanel } from "../../components/card"
import Container from "../../components/container"
import ZeroState, {ZeroStateTitle} from "../../components/zerostate"
import Vector from "../../components/vector"
import Wrapper from "../../components/wrapper"
import Button, {ButtonWrapper} from "../../components/button"

import * as CONSTANTS from "../../services/constant"
import { useTheme } from "../../services/theme"

import hubspotCard from "../../images/hubspotcard.svg"
import grayBlob from "../../images/grayblob.svg"
import integrationZero from "../../images/integrationzero.svg"

const getAccountDetails = ({oAuthProviderKey, accountKey, metaData}) => {
    switch(oAuthProviderKey) {
        case CONSTANTS.HUBSPOT_OAUTH_PROVIDER_KEY: 
            return {
                key: accountKey,
                provider: oAuthProviderKey,
                title: metaData.domain,
                category: metaData.user,
                src: hubspotCard
            }
        default: 
            throw (new Error(`Invalid OAuth provider key ${oAuthProviderKey} on account ${accountKey}`))
    }
}

const IntegrationGallery = () => {

    const theme = useTheme()
    const permissions = useSelector(state => state.permissions)  
    const integrations = (permissions && permissions.oAuthAccounts) ? 
        Object.values(permissions.oAuthAccounts).map(account => getAccountDetails(account)) : []

    return (
        <AppLayout
            ctaPath="/app/integrations/create"
            ctaContent="Add Integration"
            contextTitle="All integrations"
        >
            <SEO title="My Integrations" />
            <Wrapper         
                backgroundImage={`url(${grayBlob})`}
                backgroundSize={`auto 100%`}
                backgroundPosition={`bottom right`}
                backgroundRepeat={`no-repeat`}
                minHeight="calc(100vh - 65px)"
            >
                <Container>
                    <CardPanel 
                        marginTop={(!integrations || integrations.length === 0) ? "0px" : "50px"} 
                        marginBottom={(!integrations || integrations.length === 0) ? "0px" : "50px"} 
                    >
                        {integrations.map(({key, provider, category, title, src}) => 
                            <Card
                                key={key}
                                to={`/app/integrations/${key}?${CONSTANTS.INTEGRATION_PROVIDER_KEY}=${provider}`}
                                category={category}
                                title={title}
                                callToAction="Open &#8594;"
                                src={src}
                            />
                        )}
                    </CardPanel>
                    <ZeroState 
                        show={!integrations || integrations.length === 0} 
                        paddingTop="10%" 
                        breakpoint={theme.breakpoints.tablet} 
                        responsiveStyle="padding-left: 10%; padding-right: 10%;"
                    >
                        <ZeroStateTitle maxWidth="400px">No integrations yet. Connecting Vimkit to other platforms is easy!</ZeroStateTitle>
                        <ButtonWrapper paddingTop="10px" paddingBottom="30px">
                            <Link to="/app/integrations/create">
                                <Button type="button" marginLeft="5px" marginRight="5px">Add Integration</Button>
                            </Link>  
                        </ButtonWrapper>
                        <Vector src={integrationZero} paddingTop="3%" maxWidth="250px"/>
                    </ZeroState>
                </Container>
            </Wrapper>
            
        </AppLayout>
    )
}
export default IntegrationGallery