import React from "react"
import styled from "styled-components"
import Spinner from "./spinner"

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({width}) => width || "100%"};
    
    height: ${({show, height}) => show ? (height || "100%") : "0px"};
    overflow: ${({show}) => show ? "auto" : "hidden"};
    opacity: ${({show}) => show ? 1 : 0};
    visibility: ${({show}) => show ? "visible" : "hidden"};
    transition: visibility 200ms cubic-bezier(.455, .03, .515, .955), 
        opacity 200ms cubic-bezier(.455, .03, .515, .955);
`

const LoadState = ({spinnerSize, show, ...rest}) => (
    <StyledDiv size={spinnerSize}  show={show} {...rest}>
        <Spinner size={spinnerSize}  show={show}/>
    </StyledDiv>
)

export default LoadState