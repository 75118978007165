import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"

import SEO from "../components/seo"
import Header from "../components/header"
import LoadState from "../components/loadstate"
import SiteLayout from "../components/sitelayout"
import MenuItem from "../components/menuitem"
import ErrorState from "../components/errorstate"

import { useAuth } from "../services/auth" 
import { createOAuthToken } from "../appstate/permissions"
import { useTheme } from "../services/theme"
import { handleEventError } from "../services/error"
import { getFromQueryString, decodeBase64Uri, setLocalStorageItem, callThunk } from "../services/helper" 
import * as CONSTANTS from "../services/constant"

const DEFAULT_ERROR_MESSAGE = `Oops! Something's not right. Please try again or reach out to customer support.`
const DEFAULT_ERROR_CODE = `500`


const Authorize = () => {
    const auth = useAuth()
    const dispatch = useDispatch()
    const user = auth.getUser()
    const theme = useTheme()
    const [error, setError] = useState(false)
    const [oAuthState, setOAuthState] = useState(null)
    

    useEffect(() => {

        if(!oAuthState) {

            const encodedOAuthState = getFromQueryString(`state`)
            if(!encodedOAuthState) {
                setError(true)
                return
            } 
            const decodedOAuthState = JSON.parse(decodeBase64Uri(encodedOAuthState))
            if(!decodedOAuthState || !decodedOAuthState.redirectPath) {
                setError(true)
                return
            } 

            const { codeKey, errorCodeKey, errorMessageKey } = decodedOAuthState

            const code = getFromQueryString(codeKey)
            const oAuthErrorCode = getFromQueryString(errorCodeKey) 
            const oAuthErrorMessage = getFromQueryString(errorMessageKey)

            setOAuthState({ code, oAuthErrorCode, oAuthErrorMessage, ...decodedOAuthState })

        } else {

            const { code, oAuthErrorCode, oAuthErrorMessage, oAuthMode, redirectPath } = oAuthState

            if(oAuthMode === CONSTANTS.OAUTH_MODE_POPUP){
                if(!code || oAuthErrorCode){
                    setLocalStorageItem(CONSTANTS.OAUTH_RESULT_KEY, JSON.stringify({
                        errorCode: oAuthErrorCode || DEFAULT_ERROR_CODE,
                        errorMessage: oAuthErrorMessage || DEFAULT_ERROR_MESSAGE,
                    }))
                } else {
                    setLocalStorageItem(CONSTANTS.OAUTH_RESULT_KEY, JSON.stringify({code}))
                }                
            } else {
                if(!code || oAuthErrorCode) {
                    navigate(redirectPath, {
                        state: {
                            errorCode: oAuthErrorCode || DEFAULT_ERROR_CODE, 
                            errorMessage: oAuthErrorMessage || DEFAULT_ERROR_MESSAGE
                        }, 
                        replace: true
                    })
                }     
            }
        }
    },[oAuthState])

    useEffect(() => {

        if(user && oAuthState) {

            const { code, oAuthMode, oAuthProviderKey, redirectPath, oAuthErrorCode } = oAuthState

            if(oAuthMode !== CONSTANTS.OAUTH_MODE_REDIRECT || !code || oAuthErrorCode) {
                return
            }

            let unmounted = false
            const processAuthCode = async () => {
                try {
                    await callThunk(dispatch, createOAuthToken, [user, oAuthProviderKey, code, CONSTANTS.OAUTH_TOKEN_TYPE_ACCESS])
                    navigate(redirectPath, {replace: true})     
                } catch (err) {
                    handleEventError(err, user, false)
                    if(!unmounted) {
                        setError(true)
                    }
                }
    
            }
            processAuthCode()
            return () => unmounted = true
        }
    }, [user, dispatch, oAuthState])

    return (
        <SiteLayout 
            hideLogin={true} 
            hideSignup={true} 
            hideMyWebsites={true}
            menu={
                <MenuItem to="/help" marginLeft="5px" marginRight="5px">Help Center</MenuItem>
            }
        >
            <SEO title="Authorize" />
            {
                !error && 
                <Header 
                    title={`Connecting${oAuthState ? " "+oAuthState.oAuthProviderKey : ""}...`}
                    subTitle="Please wait. This may take a few seconds."
                    minHeight="30vh"
                    paddingTop="80px"
                    paddingBottom="80px"
                /> 
            }
            <LoadState
                spinnerSize="40px"
                height="200px"  
                show={!error}
            />
            <ErrorState
                show={error}
                paddingTop="10%" 
                breakpoint={theme.breakpoints.tablet} 
                responsiveStyle="padding-left: 10%; padding-right: 10%;"
                title="Oops! Connection failed."
            >
                (oAuthState && oAuthState.oAuthErrorMessage)? oAuthState.oAuthErrorMessage : DEFAULT_ERROR_MESSAGE
            </ErrorState>
        </SiteLayout>
    )
}

export default Authorize