import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import GoogleButton from "../components/googlebutton"
import Button, { ButtonWrapper } from "../components/button"
import Header from "../components/header"
import SEO from "../components/seo"
import SiteLayout from "../components/sitelayout"
import FloatSegment from "../components/floatsegment"
import Wrapper from "../components/wrapper"
import FlowContainer from "../components/flowcontainer"
import LoadState from "../components/loadstate"

import blueWave from "../images/bluewave.svg"

import { useTheme } from "../services/theme"
import { useAuth } from "../services/auth"
import { isBrowser, getLocalStorageItem } from "../services/helper" 
import * as CONSTANTS from "../services/constant"

const Login = ({location}) => {
    const theme = useTheme()
    const auth = useAuth()
    const path = (isBrowser() && location.state) ? location.state.redirectPath : null
    const wasLoggedIn = getLocalStorageItem(CONSTANTS.USER_STATUS_CACHE_KEY) === CONSTANTS.USER_STATUS_LOGGED_IN ? true : false
    const [loading, setLoading] = useState(wasLoggedIn)  
    const [waiting, setWaiting] = useState(false)
    const [autoRedirect, setAutoRedirect] = useState(true)  

    useEffect(() => {
        if(auth.isLoggedIn()) {
            setLoading(false)
        }
    },[auth])

    useEffect(() => {
        if(loading) {
            const timer = setTimeout(() => {
                if(loading) {
                    setLoading(false)
                }
            }, 5000)
            return () => clearTimeout(timer)
        }

    },[loading])

    if(loading) {
        return (
            <LoadState 
                height="100vh" 
                width="100vw"
                spinnerSize="40px"
                show={true}
            />
        )
    }

    return (

        <SiteLayout 
            hideLogin={true} 
            hideUserProfile={true} 
            hideMyWebsites={true}
            signup={
                <Link to="/tools/hubspot-webflow-form-tool"><Button secondary="true">Get free tools</Button></Link>
            }
        >
        {/*
        <SiteLayout 
            hideLogin={true} 
            hideUserProfile={true} 
            hideMyWebsites={true}
            setSignupAutoRedirect={setAutoRedirect}
            signupWaiting={waiting}
            setSignupWaiting={setWaiting}
        >*/}
            <SEO title="Login" />
            <Wrapper
                backgroundImage={`url(${blueWave})`}
                backgroundSize={`100% 55%`}
                backgroundPosition={`center bottom`}
                backgroundRepeat={`no-repeat`}            
                minHeight="calc(100vh - 65px)"
                breakpoint={theme.breakpoints.mobile}
                responsiveStyle={`background: none;`}
            >
                <FlowContainer minHeight="calc(100vh - 65px)">
                    <FloatSegment maxWidth="480px" marginLeft="auto" marginRight="auto" marginBottom="65px" shadow={true}>
                        <Header 
                            title="Welcome back."
                            subTitle="Sign in to your account."
                            paddingTop="40px"
                            paddingBottom="40px"
                        /> 
                        <ButtonWrapper paddingBottom="80px">
                            <GoogleButton 
                                show={true}
                                autoRedirect={autoRedirect} 
                                setAutoRedirect={setAutoRedirect}
                                waiting={waiting}
                                setWaiting={setWaiting}
                                loggedInPath={path}
                                minWidth="240px"
                            >
                                Continue with Google
                            </GoogleButton>
                        </ButtonWrapper>
                    </FloatSegment>
                </FlowContainer>
            </Wrapper>
        </SiteLayout>
    )
}

export default Login