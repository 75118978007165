import React from "react"
import styled from "styled-components"
import logo from "../images/logo.svg"

const StyledWrapper = styled.div`
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${({background}) => background ? `background: ${background};` : ""}
    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {${responsiveStyle}}` : ""}
`

export const Modal = styled.div`
    border-radius: 10px;
    background: ${({theme, backgroundColor}) => backgroundColor || theme.colors.background};
    ${({minWidth}) => minWidth? `min-width: ${minWidth};` : ""}
    ${({minHeight}) => minHeight? `min-height: ${minHeight};` : ""}
    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {${responsiveStyle}}` : ""}

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        border-radius: 0px;
        width: 100%;
        height: 94%;
    }
`

const StyledImg = styled.img`
    max-height: ${({maxHeight}) => maxHeight || "40px"};
    margin-top: ${({marginTop}) => marginTop || "40px"};
    margin-bottom: ${({marginBottom}) => marginBottom || "20px"};;
`
export const ModalLogo = props => (
    <StyledImg src={logo} {...props}/>
)


const ModalLayout = ({children, ...rest}) => (
    <StyledWrapper {...rest}>
        {children}
    </StyledWrapper>
)
  
export default ModalLayout