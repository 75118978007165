import React from "react"
import styled from "styled-components"
 
const StyledDiv = styled.div`
    border-radius: 10px;
    background-color: ${({theme}) => theme.colors.background};
    padding-left: ${({paddingLeft}) => paddingLeft || "5%"};
    padding-right: ${({paddingRight}) => paddingRight || "5%"};
    padding-top: ${({paddingTop}) => paddingTop || "5%"};
    padding-bottom:  ${({paddingBottom}) => paddingBottom || "5%"};
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    ${({marginLeft}) => marginLeft ? `margin-left: ${marginLeft};` : ""}
    ${({marginRight}) => marginRight ? `margin-right: ${marginRight};` : ""}
    ${({marginTop}) => marginTop ? `margin-top: ${marginTop};` : ""}
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    ${({shadow, theme}) => shadow ? `box-shadow: ${theme.shadow};` : ""}
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.mobile}) {
        padding-left: 0%;
        padding-right: 0%;
        box-shadow: none;
        ${({responsiveStyle}) => responsiveStyle}
    }
`


export default ({ children, ...rest }) => (
    <StyledDiv {...rest}>
        {children}               
    </StyledDiv>
)