import React, {useEffect, useState} from "react"
import {useSelector, useDispatch} from "react-redux"
import { Link } from "gatsby"

import AppLayout from "../components/applayout"
import SEO from "../components/seo"
import LoadState from "../components/loadstate"
import Section from "../components/section"
import Segment, { SegmentTitle, SegmentSubTitle} from "../components/segment"
import TextDiv from "../components/textdiv"
import Button, { ButtonWrapper } from "../components/button"
import ReadOnlyField from "../components/readonlyfield"
import CircleImage from "../components/circleimage"
import TextInput from "../components/textinput"
import Wrapper from "../components/wrapper"
import Ellipsis from "../components/ellipsis"
import ErrorMessage from "../components/errormessage"

import exclamationDoodle from "../images/exclamationdoodle.svg"
import grayWave from "../images/graywave.svg"

import { fetchProfile, updateProfile } from "../appstate/userprofile"
import { useAuth } from "../services/auth"
import { useTheme } from "../services/theme"
import { callThunk, isEmptyObject } from "../services/helper"
import { handleEventError } from "../services/error"

const Account = () => {

    const dispatch = useDispatch()
    const userProfile = useSelector(state => state.userProfile)
    const user = useAuth().getUser()
    const theme = useTheme()
    const [loading, setLoading] = useState(true)
    const [company, setCompany] = useState('')
    const [title, setTitle] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [validationError, setValidationError] = useState(false)

    const handleUpdate = async (e) => {
        try {
            e.preventDefault()
            //save profile info
            setSubmitting(true)
            await callThunk( dispatch, updateProfile, [ user, { ...userProfile, company: company, title: title }])
        } catch (err) {
            console.log(err)
            setErrorMessage("Oops! Something's not right. Please try again or reach out to customer support.")
            setValidationError(true)
            handleEventError(err, user, false)
        } finally {
            setSubmitting(false)
        }
    }

    useEffect(() => {
        if(!loading) {
            return
        }

        if(!isEmptyObject(userProfile)) {
            setLoading(false)
            return
        }

        if(!user) {
            return
        }

        let unmounted = false
        const loadUserProfile = async () => {
            try {
                await callThunk(dispatch, fetchProfile, [user])
            } catch (err) {
                handleEventError(err, user, false)
                if(!unmounted) {
                    setErrorMessage("Oops! Couldn't load your profile. Try refreshing the page or reach out to customer support.")
                    setValidationError(true)    
                }
            } finally {
                if(!unmounted) {
                    setLoading(false)
                }
            }
        }
        loadUserProfile()
        return () => unmounted = true
    },[user, userProfile, dispatch, loading])

    useEffect(()=>{
        if(!userProfile.company) {
            return
        }
        setCompany(userProfile.company)
    },[userProfile.company])

    useEffect(()=>{
        if(!userProfile.title) {
            return
        }
        setTitle(userProfile.title)
    },[userProfile.title])

    return (
        <AppLayout
            ctaPath="/help/account/delete"
            ctaContent="See FAQs"
            contextTitle={(user.displayName ? (user.displayName.split(" ")[0] + "'s account"): "My account")}
        >
            <SEO title="My Account" />
            <Section paddingBottom="60px" paddingTop="60px">
                <Segment
                    header={<SegmentTitle>Your Profile</SegmentTitle>}
                >
                    {user.photoURL && <CircleImage src={user.photoURL} maxWidth="100px" marginBottom="20px"/>}
                    <ReadOnlyField fieldName="Name" paddingBottom="20px" labelColor={theme.colors.extraSubtleText}>
                        { user.displayName ? user.displayName : ""}
                    </ReadOnlyField>
                    <ReadOnlyField fieldName="Email" paddingBottom="20px" labelColor={theme.colors.extraSubtleText}>
                        { user.email ? user.email : ""}
                    </ReadOnlyField>
                    { 
                        user.phoneNumber && 
                        <ReadOnlyField fieldName="Phone" paddingBottom="20px" labelColor={theme.colors.extraSubtleText}>
                            user.phoneNumber
                        </ReadOnlyField> 
                    }
                    <ReadOnlyField fieldName="Created" paddingBottom="30px" labelColor={theme.colors.extraSubtleText}>
                        { (user.metadata && user.metadata.creationTime) ? user.metadata.creationTime : ""}
                    </ReadOnlyField>
                    <LoadState 
                        spinnerSize="40px"
                        height="145px"  
                        show={loading} 
                        width="60px"
                    /> 
                    {
                        !loading &&
                        <>
                            <TextInput 
                                placeholder="Where do you work?" 
                                label="Company"
                                type="text" maxWidth="400px" 
                                marginBottom="30px"
                                value={company} 
                                onChange={e => setCompany(e.target.value)}
                            /> 
                            <TextInput 
                                placeholder="What's your title?" 
                                label="Title"
                                type="text" maxWidth="400px" 
                                marginBottom="15px"
                                value={title} 
                                onChange={e => setTitle(e.target.value)}
                            />
                        </>  
                    }
                    <ErrorMessage minHeight="30px" paddingBottom="15px">
                        {validationError? errorMessage : ""}
                    </ErrorMessage>
                    <ButtonWrapper align="left" maxWidth="540px">
                        <Button type="button" disabled={submitting} onClick={handleUpdate}>
                        {!submitting ? `Update Account` : <Ellipsis/>}
                        </Button>
                    </ButtonWrapper>  
                                 
                </Segment>
            </Section>
            <Wrapper 
                backgroundImage={`url(${exclamationDoodle}), url(${grayWave})`}
                backgroundSize={`100px auto, cover`}
                backgroundPosition={`85% 55%, top left`}
                backgroundRepeat={`no-repeat, no-repeat`}
                breakpoint={theme.breakpoints.mobile}
                responsiveStyle={`background-image: url(${grayWave}); background-size: cover; background-position: top left; background-repeat: no-repeat;`}
            >
                <Section paddingBottom="60px" paddingTop="100px">
                    <Segment
                        header={<SegmentTitle>Delete Account</SegmentTitle>}
                    >
                        <SegmentSubTitle>Danger Zone</SegmentSubTitle>
                        <TextDiv 
                            maxWidth="540px"
                            marginBottom="20px"
                            align="left"
                            breakpoint={theme.breakpoints.mobile}
                            responsiveAlign="center"
                        >
                            No undo. Tread lightly!<br/><br/>
                            This will permanently remove all your websites from Vimkit and delete your account.
                        </TextDiv>
                        <ButtonWrapper align="left" maxWidth="540px">
                            <Link to="/app/delete">
                                <Button type="button">Delete Account</Button>
                            </Link>
                        </ButtonWrapper>
                    </Segment>
                </Section>
            </Wrapper>

        </AppLayout>
    )
}
export default Account