import React from "react"
import styled from "styled-components"

const StyledDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    border-top-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: ${({error, theme}) => error ? theme.colors.error : theme.colors.inputBorder};
    margin-top: ${({marginTop}) => marginTop || "15px"};
    margin-bottom: ${({marginBottom}) => marginBottom || "0px"};
    margin-left: ${({marginLeft}) => marginLeft || "auto"};
    margin-right: ${({marginRight}) => marginRight || "auto"};
    padding-top: 17px;
    padding-bottom: 2px;
    width: 100%;
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
`
const Label = styled.span`
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    background-color: ${({theme}) => theme.colors.background};
    position: absolute;
    top: -10px;
    left: 20px;
    color: ${({error, theme}) => error? theme.colors.error : theme.colors.inputBorder};
    font-size: 12px;
`

const RadioButtonSet = ({error, label, children, ...rest}) => {

    return (
        <StyledDiv {...rest}>
            <Label error={error} >{label}</Label>
            {children}
        </StyledDiv>
    )
}

export default RadioButtonSet