import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"
import { useMatch } from "@reach/router"

import Container from "./container"

const FullWidthWrapper = styled.div`
    ${({color}) => color ? `color: ${color};` : ""}
    ${({backgroundColor}) => backgroundColor ? `background-color: ${backgroundColor};` : ""}
    ${({paddingTop}) => paddingTop ? `padding-top: ${paddingTop};` : ""}
    ${({paddingBottom}) => paddingBottom ? `padding-bottom: ${paddingBottom};` : ""}

`
const StyledDiv = styled.div`
    padding-left: 134px;
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        padding-left: 0px;
        padding-top: ${({first}) => first ? "130px" : "50px"};
    }

`

export const LeftNavSection = ({children, first, ...rest}) => (
    <FullWidthWrapper {...rest}>
        <Container>
            <StyledDiv first={first}>
                {children}
            </StyledDiv>
        </Container>
    </FullWidthWrapper>
)


const StyledLink = styled(Link)`
    background-color: ${({selected, theme}) => selected ? theme.colors.secondary : theme.colors.background};
    color: ${({selected, theme}) => selected ? theme.colors.inverseText : theme.colors.text};
    padding-left: 21.5px;
    padding-right: 21.5px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 103px;
    text-decoration: none;
    border-top-left-radius: ${({first}) => first ? "10px" : "0px"};
    border-top-right-radius: ${({first}) => first ? "10px" : "0px"};
    border-bottom-left-radius: ${({last}) => last ? "10px" : "0px"};
    border-bottom-right-radius: ${({last}) => last ? "10px" : "0px"};

    &:hover {
        color: ${({selected, theme}) => selected ? theme.colors.inverseText : theme.colors.secondary};;
    }

    transition: color 200ms cubic-bezier(.455, .03, .515, .955), background-color 200ms cubic-bezier(.455, .03, .515, .955);

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        border-top-left-radius: ${({first}) => first ? "10px" : "0px"};
        border-bottom-left-radius: ${({first}) => first ? "10px" : "0px"};
        border-top-right-radius: ${({last}) => last ? "10px" : "0px"};
        border-bottom-right-radius: ${({last}) => last ? "10px" : "0px"};
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 10px; 
        width: 80px;   
    }

`
const StyledText = styled.span`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    margin-top: 5px;
    line-height: 10px;
`

const StyledImg = styled.img`
    max-height: 30px;
    transition: filter 200ms cubic-bezier(.455, .03, .515, .955);
    
    ${({selected}) => !selected ? 
        "" : 
        "filter: invert(100%) sepia(100%) saturate(14%) hue-rotate(212deg) brightness(104%) contrast(104%);"}

    ${StyledLink}:hover & {
        ${({selected}) => !selected ? 
            "filter: brightness(0) saturate(100%) invert(61%) sepia(85%) saturate(1094%) hue-rotate(168deg) brightness(102%) contrast(101%);" : 
            ""}
        
    }

`
export const LeftNavItem = ({src, children, to, ...rest}) => {
    const selected = !!useMatch(to)
    return (
        <StyledLink selected={selected} to={to} {...rest}>
            <StyledImg src={src} selected={selected}/>
            <StyledText>{children}</StyledText>
        </StyledLink>

    )
}

const StyledWrapper = styled.div`
    position: fixed;
    top: 115px;
    left: 2.5%;
    background-color: ${({theme}) => theme.colors.background};
    box-shadow: ${({theme}) => theme.shadow};
    z-index: 9;
    border-radius: 10px;
    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        top: 95px;
        left: 50%;
        transform: translateX(-50%);
        height: 70px;
        display: flex;
        flex-direction: row;
    }
`

export default ({ children, ...rest }) => (
    <StyledWrapper {...rest}>
        {children}
    </StyledWrapper>    
)