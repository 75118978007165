import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
    opacity: ${({show}) => show ? 1 : 0};
    visibility: ${({show}) => show ? "visible" : "hidden"};
    transition: visibility ${({fadeSpeed}) => fadeSpeed || "200ms"} cubic-bezier(.455, .03, .515, .955), 
        opacity ${({fadeSpeed}) => fadeSpeed || "200ms"} cubic-bezier(.455, .03, .515, .955); 
`

export default ({show, fadeSpeed, children, ...rest}) => (
    <StyledWrapper show={show} fadeSpeed={fadeSpeed} {...rest}>{children}</StyledWrapper>
)
