import React from "react"

import LeftNav, { LeftNavItem } from "./leftnav"
import settingsIcon from "../images/settings.svg"
import formIcon from "../images/form.svg"
//import activityIcon from "../images/activity.svg"

export default ({websiteId, ...rest }) => (
    <LeftNav {...rest}>
        {/*
        <LeftNavItem 
            to={`/app/websites/${websiteId}`} 
            src={activityIcon}
        >
            Activity
        </LeftNavItem>
        */}
        <LeftNavItem 
            to={`/app/websites/${websiteId}/forms`} 
            src={formIcon}
            first="true"
        >
            Forms
        </LeftNavItem>
        <LeftNavItem 
            to={`/app/websites/${websiteId}/settings`} 
            src={settingsIcon}
            last="true"
        >
            Settings
        </LeftNavItem>
    </LeftNav>
)