import React, { useRef, useEffect, useState } from "react"
import lottie from "lottie-web"
import styled from "styled-components"

//https://airbnb.io/lottie/#/web

const StyledWrapper = styled.div`
    width: ${({width}) => width || "100%"};
    height: ${({height}) => height || "100%"};
`
const Lottie = ({loop = false, autoplay = false, animationData, paused = true, startFrame, stopFrame, speed, direction, onComplete, onLoopComplete, onEnterFrame, onSegmentStart, ...rest}) => {
    const container = useRef()
    const [animation, setAnimation] = useState(null)

    useEffect(()=>{
        const animation = lottie.loadAnimation({
            loop: loop, 
            autoplay: autoplay, 
            animationData: animationData, 
            renderer: 'svg', 
            container: container.current
        })
        setAnimation(animation)
    },[loop, autoplay, animationData])

    useEffect(() => {
        if(!animation){
            return
        }
        if(paused) {
            animation.pause()
            return
        }
        if(startFrame && stopFrame){
            animation.playSegments([startFrame, stopFrame, true])
            return
        }
        if(startFrame){
            animation.goToAndPlay(startFrame, true)
            return
        }
        if(stopFrame){
            animation.goToAndStop(stopFrame, true)
            return
        }
        animation.play()

    },[animation, paused, startFrame, stopFrame])
    
    useEffect(() => {
        if(animation && speed) {
            animation.setSpeed(speed)
        }
    },[animation, speed])

    useEffect(() => {
        if(animation && direction) {
            animation.setDirection(direction)
        }
    },[animation, direction])

    useEffect(() => {
        if(animation && onComplete) {
            animation.onComplete = onComplete
        }
    },[animation, onComplete])

    useEffect(() => {
        if(animation && onLoopComplete) {
            animation.onLoopComplete = onLoopComplete
        }
    },[animation, onLoopComplete])

    useEffect(() => {
        if(animation && onEnterFrame) {
            animation.onEnterFrame = onEnterFrame
        }
    },[animation, onEnterFrame])

    useEffect(() => {
        if(animation && onSegmentStart) {
            animation.onSegmentStart = onSegmentStart
        }
    },[animation, onSegmentStart])

    return (
        <StyledWrapper ref={container} {...rest}/>
    )
}

export default Lottie