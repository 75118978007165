import React from "react"
import { useDispatch } from "react-redux"

import CardButton from "./cardbutton"

import { useAuth } from "../services/auth"
import { initiateOAuth } from "../services/oauth"
import * as CONSTANTS from "../services/constant"
import { handleEventError } from "../services/error"

import connectIcon from "../images/connectbuttonicon.svg"

const HUBSPOT_OAUTH_SCOPE = `oauth forms`

export default ({redirectPath, show, setErrorMessage, setValidationError}) => {
    const dispatch = useDispatch()
    const user = useAuth().getUser()
    const connectHubspot = e => {
        try {
            e.preventDefault()

            const hubspotOAuthUrl = `${process.env.GATSBY_HUBSPOT_OAUTH_URL}?client_id=${encodeURIComponent(process.env.GATSBY_HUBSPOT_CLIENT_ID)}`+
            `&scope=${encodeURIComponent(HUBSPOT_OAUTH_SCOPE)}`+
            `&redirect_uri=${encodeURIComponent(process.env.GATSBY_ORIGIN +'/app/authorize')}`
    
            initiateOAuth(user, dispatch, {
                codeKey: CONSTANTS.HUBSPOT_OAUTH_AUTH_CODE_KEY,
                errorCodeKey: CONSTANTS.HUBSPOT_OAUTH_ERROR_CODE_KEY,
                errorMessageKey: CONSTANTS.HUBSPOT_OAUTH_ERROR_MESSAGE_KEY,
                oAuthProviderKey: CONSTANTS.HUBSPOT_OAUTH_PROVIDER_KEY,
                oAuthUrl: hubspotOAuthUrl,
                redirectPath,
                setErrorMessage,
                setValidationError
            })
        } catch (err) {
            handleEventError(err, false, false)
        }
    }

    return (
        <CardButton
            display={show ? "flex" : "none"}
            onClick={connectHubspot}
            maxWidth="540px"
            marginBottom="10px"
            src={connectIcon}
            title="Connect Hubspot Account &#8594;"
            subTitle="Once you connect your Hubspot account, you can easily choose from your existing forms. You can disconnect anytime."
        />
    )
}
