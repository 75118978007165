import React from "react"
import styled from "styled-components"
import Container from "./container"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${({minHeight}) => minHeight ? `min-height: ${minHeight};` : ""}
`

const FlowContainer = ({children, ...rest}) => (
    <Container>
        <Wrapper {...rest}>
            {children}
        </Wrapper>
    </Container>
)
export default FlowContainer