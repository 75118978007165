import React from "react"
import styled from "styled-components"

import TopNav, {TopNavLeft, TopNavRight} from "./topnav"
import Footer from "./footer"
import Main from "./main"
import UserProfile from "./userprofile"
import MenuItem, {MobileMenuItemDivider} from "./menuitem"
import Nav from "./nav"
import NavContext, {NavContextCta} from "./navcontext"
import BottomNav from "./bottomnav"
import ResponsiveToggler from "./responsivetoggler"

const Divider = styled.div`
    width: 1px;
    background-color: ${({theme}) => theme.colors.divider};
    margin-left: ${({marginLeft}) => marginLeft || "5px"};
    margin-right: ${({marginRight}) => marginRight || "5px"};
`

const ResponsiveFooterWrapper = styled.div`
    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        padding-bottom: ${({paddingBottom}) => paddingBottom};
    }
`

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        flex-direction: row-reverse;
    }
`

const AppLayout = ({contextTitle, contextCategory, ctaContent, ctaPath, hideMenu, children}) => (
    <>
        <TopNav shadow={true}>
            <TopNavLeft>
                {contextTitle && 
                    <ResponsiveToggler display="flex">
                        <Divider/>
                        <NavContext 
                            title={contextTitle}
                            category={contextCategory}
                            limit="30"
                        />
                    </ResponsiveToggler>
                }
            </TopNavLeft>
            <TopNavRight>
                {ctaContent &&
                    <ResponsiveToggler display="flex">
                        <NavContextCta to={ctaPath} secondary="true">
                            {ctaContent}
                        </NavContextCta>
                        <Divider marginRight="0px"/>
                    </ResponsiveToggler>
                }
                <StyledWrapper>
                    {
                        !hideMenu && 
                        <Nav>
                            <MenuItem to="/app/websites" marginLeft="5px" marginRight="5px">Websites</MenuItem>
                            <MobileMenuItemDivider/>
                            <MenuItem to="/help" marginLeft="5px" marginRight="5px">Help Center</MenuItem>
                        </Nav>
                    }
                    <UserProfile />
                </StyledWrapper>
            </TopNavRight>
        </TopNav>
        <Main>
            {children}
        </Main>
        <ResponsiveFooterWrapper paddingBottom={(contextTitle || ctaContent) ? "65px" : "0px"}>
            <Footer/>
        </ResponsiveFooterWrapper>
        {(contextTitle || ctaContent) && 
            <ResponsiveToggler responsiveonly="true">
                <BottomNav>
                    {contextTitle && 
                        <NavContext 
                            title={contextTitle}
                            category={contextCategory}
                            limit="20"
                        />
                    }
                    {ctaContent &&
                        <NavContextCta to={ctaPath} secondary="true" minWidth="120px">
                            {ctaContent}
                        </NavContextCta>
                    }
                </BottomNav>
            </ResponsiveToggler>
        }
    </>
  )
  
  export default AppLayout