import React, {useState} from "react"
import { navigate } from "gatsby"
import {useDispatch} from "react-redux"

import TextInput from "../components/textinput"
import ErrorMessage from "../components/errormessage"
import Button, { ButtonWrapper } from "../components/button"
import Ellipsis from "../components/ellipsis"
import TextDiv from "../components/textdiv"
import TextSpan from "../components/textspan"
import LoadState from "../components/loadstate"

import { setInputState, callThunk } from "../services/helper"
import { deleteForm } from "../appstate/websites"
import { useAuth } from "../services/auth"
import { handleEventError } from "../services/error"
import { useTheme } from "../services/theme"

const DeleteForm = ({currentWebsite, currentForm, successRedirectPath, align, backgroundColor, color, borderColor, placeholderColor}) => {
    const [confirmationText, setConfirmationText] = useState('')
    const domain = currentWebsite ? currentWebsite.submissionData.domain.slice(8) : ''
    const [validationError, setValidationError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [deleting, setDeleting] = useState(false)

    const user = useAuth().getUser()
    const theme = useTheme()
    const dispatch = useDispatch()

    const handleDelete = async (e) => {
        try {
            e.preventDefault()

            if(confirmationText !== domain) {
                setErrorMessage(`Invalid confirmation. Please type ${domain} to confirm deletion.`)
                setValidationError(true)
            }

            setDeleting(true)
            await callThunk(dispatch, deleteForm, [user, currentWebsite.submissionId, currentForm.formKey])
            navigate(successRedirectPath)
        } catch (err) {
            setErrorMessage(`Oops! Something's not right. We couldn't delete the form. Please try again or reach out to customer support.`)
            setValidationError(true)
            handleEventError(err, user, false)

        } finally {
            setDeleting(false)
        }

    }

    if(!currentWebsite || !currentForm) {
        return (
            <LoadState 
                height="255px" 
                width="60px"
                spinnerSize="40px"
                show={true}
            />
        )
    }
    
    return (
        <>
            <TextDiv 
                maxWidth="540px"
                marginBottom="20px"
                align={align}
                breakpoint={theme.breakpoints.mobile}
                responsiveAlign="center"
            >
                No undo. Tread lightly!<br/><br/>
                This will permanently remove the form from {domain}. To proceed, type 
                <TextSpan fontStyle="italic" color={theme.colors.primary}>&nbsp;{domain}&nbsp;</TextSpan>and click remove form. 
            </TextDiv>
            <TextInput 
                placeholder={domain}
                label="Confirm Removal"
                type="text" maxWidth="540px" 
                marginLeft="0px"
                marginRight="0px"
                marginBottom="10px"
                value={confirmationText} 
                onChange={e => setInputState(e.target.value, setConfirmationText, false, setValidationError)}
                error={validationError ? true : false}
                color={color}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                placeholderColor={placeholderColor}
            />
            <ErrorMessage 
                maxWidth="540px" 
                align={align}
                breakpoint={theme.breakpoints.mobile}
                responsiveAlign="center"
            >
                {validationError? errorMessage : ""}
            </ErrorMessage>
            <ButtonWrapper align={align} maxWidth="540px">
                <Button type="button" disabled={deleting} onClick={handleDelete}>
                    {!deleting ? `Remove Form` : <Ellipsis/>}
                </Button>
            </ButtonWrapper>
        </>
    )
} 

export default DeleteForm