import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"

import AppLayout from "../../components/applayout"
import SEO from "../../components/seo"
import TextDiv from "../../components/textdiv"
import WebsiteNav from "../../components/websitenav"
import { LeftNavSection } from "../../components/leftnav"
import Segment, {SegmentTitle, SegmentSubTitle} from "../../components/segment"
//import Button, {ButtonWrapper} from "../../components/button"
//import ExternalLink from "../../components/externallink"
import Wrapper from "../../components/wrapper"
import ErrorState from "../../components/errorstate"

import blocksDoodle from "../../images/blocksdoodle.svg"
import exclamationDoodle from "../../images/exclamationdoodle.svg"
import graySquiggle from "../../images/graysquiggle.svg"
import grayWave from "../../images/graywave.svg"

import VerifyForm from "../../appfeatures/verifyform"
import DeleteForm from "../../appfeatures/deleteform"
import { fetchWebsites } from "../../appstate/websites"
import { useAuth } from "../../services/auth"
import { useTheme } from "../../services/theme"
import { handleEventError } from "../../services/error"
import { callThunk } from "../../services/helper"


const FormSettings = ({websiteId, formKey}) => {

    const currentWebsite = useSelector(state => state.websites.find(website => website.submissionId === websiteId))
    const dispatch = useDispatch()
    const auth = useAuth()
    const user = auth.getUser() 
    const theme = useTheme()
    const [loadError, setLoadError] = useState(false)

    useEffect(() => {
        if(!user || currentWebsite){
            return 
        }

        //refresh websites if state is stale
        let unmounted = false
        const refreshWebsites = async () => {
            try {
                await callThunk(dispatch, fetchWebsites, [user])
            } catch (err) {
                handleEventError(err, user, false)
                if(!unmounted) {
                    setLoadError(true)
                }
            }
        } 
        refreshWebsites()
        return () => unmounted = true
    },[user, currentWebsite, dispatch])

    if(!websiteId) {
        navigate("/app/websites")
        return null
    }

    const currentForm = currentWebsite ? currentWebsite.submissionData.forms[formKey] : {}
    const domain = currentWebsite ? currentWebsite.submissionData.domain.slice(8) : ''
    const stage = currentWebsite ? currentWebsite.submissionData.stage : ''

    return (
        <AppLayout
            contextTitle={domain}
            contextCategory={stage}
            ctaPath={`/help/guides/hubspot-webflow-form-tool#customattribute`}
            ctaContent="See Guide"
        >
            <SEO title="Form Settings" />
            {
                !loadError && 
                <>
                    <WebsiteNav websiteId={websiteId}/>
                    <Wrapper
                        backgroundImage={`url(${blocksDoodle})`}
                        backgroundSize={`80px auto`}
                        backgroundPosition={`95% 85%`}
                        backgroundRepeat={`no-repeat`}
                        breakpoint={theme.breakpoints.mobile}
                        responsiveStyle="background: none;"
                    >
                        {/*
                        <LeftNavSection first="true" paddingBottom="20px">
                            <Segment header={<SegmentTitle>View Submissions</SegmentTitle>}>
                                <ButtonWrapper align="left" maxWidth="540px">
                                    <ExternalLink display="inline" to={`https://app.hubspot.com/forms/${currentForm.portalId}/${currentForm.formId}/submissions`}>
                                        <Button>See on Hubspot</Button>
                                    </ExternalLink>
                                </ButtonWrapper>
                            </Segment>
                        </LeftNavSection>
                        */}
                        <LeftNavSection first={true} paddingBottom="20px">
                            <Segment header={<SegmentTitle>Verify Attribute</SegmentTitle>}>
                                <SegmentSubTitle>Status</SegmentSubTitle>
                                {
                                    currentForm && currentForm.installStatus ? 
                                        <TextDiv align="left" marginBottom="40px" maxWidth="400px" color={theme.colors.success}>&#x2714;&nbsp;Verified</TextDiv> : 
                                        <TextDiv align="left" marginBottom="40px" maxWidth="400px" color={theme.colors.error}>&#x2718;&nbsp;Not verified</TextDiv>
                                }
                                <VerifyForm 
                                    currentWebsite={currentWebsite} 
                                    currentForm={currentForm} 
                                    successRedirectPath={`/app/websites/${websiteId}/forms/${formKey}`}
                                    align="left"
                                />
                            </Segment>
                        </LeftNavSection>
                    </Wrapper>
                    <Wrapper 
                        backgroundImage={`url(${exclamationDoodle}), url(${grayWave}), url(${graySquiggle})`}
                        backgroundSize={`100px auto, cover, 150px auto`}
                        backgroundPosition={`85% 55%, top left, 2.5% 65%`}
                        backgroundRepeat={`no-repeat, no-repeat, no-repeat`}
                        breakpoint={theme.breakpoints.mobile}
                        responsiveStyle={`background-image: url(${grayWave}); background-size: cover; background-position: top left; background-repeat: no-repeat;`}
                    >
                        <LeftNavSection paddingBottom="20px" paddingTop="70px">
                            <Segment header={<SegmentTitle>Remove Form</SegmentTitle>}>
                                <SegmentSubTitle>Danger Zone</SegmentSubTitle>
                                <DeleteForm 
                                    currentWebsite={currentWebsite} 
                                    currentForm={currentForm}
                                    successRedirectPath={`/app/websites/${websiteId}/forms`}
                                    backgroundColor={theme.colors.alternateBackground} 
                                    color={theme.colors.text} 
                                    borderColor={theme.colors.inputBorder} 
                                    align="left"
                                />
                            </Segment>
                        </LeftNavSection>
                    </Wrapper>
                </>
            }
            <ErrorState
                show={loadError}
                paddingTop="10%" 
                breakpoint={theme.breakpoints.tablet} 
                responsiveStyle="padding-left: 10%; padding-right: 10%; padding"
                title="Oops! Couldn't load that form's settings."
            >
                Something's not right. Try refreshing the page or reach out to customer support.
            </ErrorState>      
        </AppLayout>
    )
}
export default FormSettings