import React from "react"
import styled from "styled-components"
import Container from "./container"

export const SectionTitle = styled.h2`
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 10px;
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 24px;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        font-size: 20px;
    }
`

export const SectionSubtitle = styled.div`
    font-size: 14px;
    line-height: 18px;
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
`

const StyledHeader = styled.header`
    display: flex;
    flex-direction: column;
    padding-top: ${({paddingTop}) => paddingTop || "20px"};
    padding-bottom: ${({paddingBottom}) => paddingBottom || "20px"};
    text-align: ${({align}) => align || "center"};
    align-items: ${({align}) => align === `left` ? `flex-start` : `center`};
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    ${({marginLeft}) => marginLeft ? `margin-left: ${marginLeft};` : ""}
    ${({marginRight}) => marginRight ? `margin-right: ${marginRight};` : ""}

    @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
        align-items: center;
        text-align: center;
        max-width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }
`

export const SectionHeader = ({title, children, ...rest}) => (
    <StyledHeader {...rest}>
        <SectionTitle>{title}</SectionTitle>
        {children}
    </StyledHeader>
)

const StyledWrapper = styled.div`
    ${({color}) => color ? `color: ${color};` : ""}
    ${({backgroundColor}) => backgroundColor ? `background-color: ${backgroundColor};` : ""}
    ${({paddingTop}) => paddingTop ? `padding-top: ${paddingTop};` : ""}
    ${({paddingBottom}) => paddingBottom ? `padding-bottom: ${paddingBottom};` : ""}
    ${({paddingRight}) => paddingRight ? `padding-right: ${paddingRight};` : ""}
    ${({paddingLeft}) => paddingLeft ? `padding-left: ${paddingLeft};` : ""}

    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {${responsiveStyle}}` : ""}
`

export default ({children, ...rest}) => (
    <StyledWrapper {...rest}>
        <Container>
            {children}
        </Container>
    </StyledWrapper>
)