import styled from "styled-components"

const StyledImg = styled.img`
    border-radius: 50%;
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    ${({minWidth}) => minWidth ? `min-width: ${minWidth};` : ""}
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    ${({marginTop}) => marginTop ? `margin-top: ${marginTop};` : ""}

`
export default StyledImg
