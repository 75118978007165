import React, { useRef } from "react"
import styled from "styled-components"
import { handleEventError } from "../services/error"

export const EmbedStep = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 540px;
    min-width: 340px;
    margin-bottom: ${({marginBottom}) => marginBottom || "5px"};
`

export const EmbedTitle = styled.h4`
    margin-top: ${({marginTop}) => marginTop || "0px"};
    margin-bottom: ${({marginBottom}) => marginBottom || "10px"};
    text-align: ${({align}) => align || `center`};
    font-size: 16px;
    font-weight: 600;
    @media (max-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.mobile}) {
        ${({responsiveAlign}) => responsiveAlign ? `text-align: ${responsiveAlign};` : ""}
    }
`

export const EmbedDescription  = styled.span`
    text-align: ${({align}) => align || `center`};
    margin-bottom: 20px;

    @media (max-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.mobile}) {
        ${({responsiveAlign}) => responsiveAlign ? `text-align: ${responsiveAlign};` : ""}
    }     
`

const StyledTextArea = styled.textarea`
    display: inline-block;
    border-style: none;
    outline: none;
    font-size: 12px;
    color: ${({theme}) => theme.colors.subtleText};
    width: 78%;
    height: ${({componentHeight}) => componentHeight ? `calc(${componentHeight}-25px-7px)` : "43px"};
    padding-left: 15px;
    overflow: auto;
    resize: none;

    ::selection { background-color: ${({theme}) => theme.colors.background}; }

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 16px;
    }
`

const StyledButton = styled.button`
    position: absolute;
    z-index: 1;
    border-radius: 5px;
    border-style: none;
    font-weight: 600;
    width: 14%;
    height: 25px;
    right: 4%;
    top:  50%;
    margin-top: -12px;
    background-color: ${({theme}) => theme.colors.background};
    cursor: pointer;
    text-transform: uppercase;
    outline: none;

    transition: background-color 200ms cubic-bezier(.455, .03, .515, .955);

    &:hover {
        background-color: ${({theme}) => theme.colors.hoverBackground};
    }
`

const StyledDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: ${({theme}) => theme.colors.inputBorder};
    ${({marginTop}) => marginTop ? `margin-top: ${marginTop};` : ""}
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    margin-left: ${({marginLeft}) => marginLeft || "auto"};
    margin-right: ${({marginRight}) => marginRight || "auto"};
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 15px;
    padding-bottom: 10px;
    min-width: 340px;
    max-width: ${({width}) => width ? width : "540px"};
    width: 100%;
    ${({height}) => height ? `height: ${height};` : ""}
`
const Label = styled.span`
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    background-color: ${({theme}) => theme.colors.background};
    position: absolute;
    top: -10px;
    left: 20px;
    color: ${({theme}) => theme.colors.inputBorder};
    font-size: 12px;
`
const Confirmation =styled.div`
    display: none;
    position: absolute;
    z-index: 9;
    background-color: ${({theme}) => theme.colors.transparentModalBackground};
    color: ${({theme}) => theme.colors.modalText};
    width: 150px;
    height: 25px;
    top: 50%;
    margin-top: -12px;
    margin-left: -75px;
    left: 50%;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    border-radius: 5px;
    box-shadow: ${({theme}) => theme.colors.hoverShadow};

`

const EmbedCode = ({children, label, height, ...rest}) => {
    const snippet = useRef()
    const modal = useRef()

    const copyHandler = e => {
        try {
            e.preventDefault()
            snippet.current.select()
            document.execCommand('copy')
            modal.current.style.display = "block"
            window.setTimeout(() => modal.current.style.display = "none", 1000)
        } catch (err) {
            handleEventError(err, false, false)
        }

    }
    
    return (
        <StyledDiv {...rest}>
            <Label>{label}</Label>
            <StyledTextArea ref={snippet} readOnly={true} componentHeight={height} value={children}/>
            <StyledButton type="button" onClick={copyHandler}>Copy</StyledButton>
            <Confirmation ref={modal}>Copied</Confirmation>
        </StyledDiv>
    )
}

export default EmbedCode